import { ModalPanelComponent } from 'src/app/modal-panel/modal-panel.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    // RouterModule.forChild([{ path: '', component: ModalPanelComponent }])

  ],
  exports:[],

})
export class ModalPanelModule { }
