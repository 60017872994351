import { QuestionModel } from './models/ListModels';
import { Injectable } from '@angular/core';
//import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  numberS=0;
  goshow=false;
  disPrice=0;
  examName="";
  modalForm=false;
  tagDiscount="";
  curretProfile;
  user_data:any;
  examTime=false;
  exam: Array<QuestionModel>;
  examMenu:Array<QuestionModel>;
  exam_id;
  

  activeDiscount={
    discount:"",
    pacId:"",
    pacName:""
  }
  

  currentExam:any = {
    examResults: ''
  };

  public questionIdx: number = 0;   //0-based


  constructor() { }

  saveDiscount(data:any){

    this.activeDiscount.discount="";
    this.activeDiscount.pacId="";
this.activeDiscount.discount=data.onlyPac;
this.activeDiscount.pacId=data.package_id;
this.activeDiscount.pacName=data.pacc["name"];
this.disPrice=data.onlyPac;
this.tagDiscount=data.pacc["name"];


console.log("saveDiscount,",this.activeDiscount)
  }


  resetCupon(){
    this.activeDiscount.discount="";
    this.activeDiscount.pacId="";
    this.activeDiscount.pacName="";
  }

}
