import { TranslatePipe } from 'src/app/translate.pipe';
import { Router } from '@angular/router';
import { DataService } from './../../data.service';
import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';
import { AlertSystemService } from './../../alert-system.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() showQuestionsNav: boolean;
  language = null;
  lang = "";
  langsOpen = false;
  questionsNavIsVisible = false;
  transPipe = new TranslatePipe();
  head1 = "";
  head2 = "";
  head3 = "";

  constructor(private authS: AuthenticationService, private dataS: DataService, private router: Router, private alertSystemService: AlertSystemService) { }

  ngOnInit() {
    this.language = localStorage.getItem("lang");
    this.head1 = this.transPipe.transform('head1');
    this.head2 = this.transPipe.transform('head2');
    this.head3 = this.transPipe.transform('head3');
  }

  toggleQuestionsNav() {
    this.questionsNavIsVisible = !this.questionsNavIsVisible;
  }


  openDialog() {
    // this.dialog.payload()
    this.dataS.modalForm = true;
  }


  goPanel() {
    this.router.navigate(['/panel/']);

  }
  nums() {
    this.dataS.numberS++;
    
    if (this.dataS.numberS % 5 == 0) {
      this.alertSystemService.passAlert();
      // this.dataS.goshow = true;
    } else {
      // this.dataS.goshow = false;
    }

  }

  openLangs() {
    console.log("123");

    this.langsOpen = !this.langsOpen;
  }

  closeLangs() {
    this.langsOpen = false;
  }

  changeLang(lang) {
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  goBack() {
    this.router.navigate(["/my-exams"])
    this.dataS.examTime = false
  }


  goToQ(Q, change) {


    // this.dataS.questionIdx
    this.dataS.examMenu[Q.questionNumber].answer = change;
    this.dataS.exam[Q.questionNumber].answer = change;

    return;
    this.dataS.questionIdx = Q.questionNumber;

    if (this.router.url == "/finish") {

      this.router.navigate(['/exam/'])

    } else {
      this.questionsNavIsVisible = false;
    }




  }

  openLogout() {
    this.alertSystemService.alertLogout().then(result => {
      if (result.isConfirmed) {
        this.authS.logout();
      }
    })
  }

}
