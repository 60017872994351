import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AlertSystemService } from 'src/app/alert-system.service';
import { ApiService } from 'src/app/services/api.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';

import { element } from 'protractor';

import { DataTableModule } from 'angular-data-table/release/dataTable.js';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalPanelComponent } from "src/app/modal-panel/modal-panel.component";
import { TranslatePipe } from '../../translate.pipe';




@Component({
  selector: 'app-users-data',
  templateUrl: './users-data.component.html',
  styleUrls: ['./users-data.component.css']
})
export class UsersDataComponent implements OnInit {


  title = "";

  displayedColumns = ['id', 'name', 'email', 'cupon', 'action'];
  dataSource: MatTableDataSource<ingramUsers>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  transPipe = new TranslatePipe();
  inagramUses: ingramUsers[] = [];


  constructor(

    private dataS: ApiService,
    private alertS: AlertSystemService,
    private myModal: NgxSmartModalService,
    private router: Router
  ) { }

  pacs;


  ngOnInit() {

    this.refresh();

  }


  openDialog(type, element) {
    console.log(type, element);
    if (type == "Delete") {
      this.dataS.deletUser(element.id).subscribe(res => {
        this.ansAlert(res);
        this.refresh();
      })
    }

    if (type == "DeletePac") {
      console.log(element)
      this.dataS.deletPac(element.id).subscribe(res => {
        this.ansAlert(res);


      })


    }
  }
  ansAlert(isoK) {
    if (isoK == true) {
      const alert1 = this.transPipe.transform('alert1');
      this.alertS.alertOk(alert1);
    } else {
      const alert2 = this.transPipe.transform('alert2');
      this.alertS.erroAlert(alert2);

    }
  }
  gologs(key) {

    switch (key) {
      case "panela":
        this.router.navigate(['panel'])

        break;
      case "logs":
        this.router.navigate(['logpage'])

        break;
      case "user-data":
        this.router.navigate(['user-data'])

        break;

      default:
        break;
    }
  }


  refresh() {
    this.inagramUses = [];
    this.dataS.getAllUsers().subscribe(res => {
      let res2: any = res;

      console.log("all users", res)

      res2.forEach(element => {



        this.inagramUses.push({
          name: element.first_name + " " + element.last_name,
          id: element.id,
          email: element.email,
          cupon: element.cupon



        })
      });
      this.dataSource = new MatTableDataSource(this.inagramUses);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

}


export interface ingramUsers {
  id: string,
  name: string;
  email: string;
  cupon: string;

}
