import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { MyExamsComponent } from './pages/my-exams/my-exams.component';
import { TranslatePipe } from './translate.pipe';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'my-exams',
    pathMatch: 'full'
  },
  { 
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'finish',
    loadChildren: () =>
      import('./pages/finish/finish.module').then((m) => m.FinishModule),canActivate: [AuthGuard]
  },
  {
    path: 'exam',
    loadChildren: () =>
      import('./pages/exam/exam.module').then((m) => m.ExamModule),canActivate: [AuthGuard]
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./pages/info/info.module').then((m) => m.InfoModule),canActivate: [AuthGuard]
  },
  {
    path: 'my-exams',
    loadChildren: () =>
      import('./pages/my-exams/my-exams.module').then((m) => m.MyExamsModule),canActivate: [AuthGuard]
  },
  {
    path: 'demographic-info',
    loadChildren: () =>
      import('./pages/demographic-info/demographic-info.module').then(
        (m) => m.DemographicInfoModule
      ),canActivate: [AuthGuard]
  },
  {
    path: 'results',
    loadChildren: () =>
      import('./pages/results/results.module').then((m) => m.ResultsModule),canActivate: [AuthGuard]
  },
  {
    path: 'panel',
    loadChildren: () => 
    import('./pages/panela/panela.module').then((m) => m.PanelaModule) ,canActivate: [AuthGuard]
  },
 
  {
    path: 'logpage',
    loadChildren: () =>
      import('./logpage/logpage.module').then(
        (m) => m.LogpageModule
        ) 
        ,canActivate: [AuthGuard]
  },
  {
    path: 'user-data',
    loadChildren: () =>
      import('./pages/users-data/users-data.module').then(
        (m) => m.UsersDataModule
        ) 
        ,canActivate: [AuthGuard]
  },
  {
    path: 'user-details',
    loadChildren: () =>
      import('./pages/user-details/user-details.module').then((m) => m.UserDetailsModule),canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
