import { RouterModule } from '@angular/router';
import { HeaderModule } from 'src/app/components/header/header.module';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ModalPanelModule } from 'src/app/modal-panel/modal-panel.module';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule, MatFormFieldModule, MatRippleModule, MatPaginatorModule, MatPaginator } from '@angular/material';
import { UsersDataComponent } from './users-data.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations:[UsersDataComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    ModalPanelModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatPaginatorModule,
    FormsModule,
    HeaderModule,
    RouterModule.forChild([{ path: '', component: UsersDataComponent }])
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
  ],

  providers:[MatPaginator],
})
export class UsersDataModule { } 
