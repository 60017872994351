import { FormsModule } from '@angular/forms';
import { UsersDataModule } from './pages/users-data/users-data.module';
import { HeaderModule } from './components/header/header.module';
import { AlertSystemService } from './alert-system.service';
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './auth.guard';
import { AuthenticationService } from './authentication.service';
import { DataService } from "./data.service";
import { FromdataComponent } from './fromdata/fromdata.component';
import { MatTableModule } from '@angular/material';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { SharedModule } from './shared.module';


export var language = 'hebrew';

var DEBUG = false;
// ENABLE/DISABLE Console Logs
if (!DEBUG) {
  window.console.log = function () { }
}





@NgModule({
  declarations: [
    AppComponent,
    FromdataComponent
  ],
  imports: [
    // ToastrModule.forRoot(),
    BrowserModule,
    HeaderModule,
    UsersDataModule,
    MatTableModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    // ResultsModule,
    // MyExamsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSmartModalModule.forRoot()
    //StoreModule.forRoot()
  ],
  providers: [
    AlertSystemService,
    AuthGuard,
    AuthenticationService,
    DataService
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
