import { DataService } from './data.service';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { Injectable } from '@angular/core';
import { language } from './app.module';
import Swal from 'sweetalert2';

import 'sweetalert2/src/sweetalert2.scss'
import { TranslatePipe } from './translate.pipe';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})
export class AlertSystemService {

  transPipe = new TranslatePipe();
  language = localStorage.getItem("lang");
  constructor(private apiS: ApiService, private dataS: DataService, private router: Router) { }


  loginFalse() {
    const alert3 = this.transPipe.transform('alert3');
    const alert4 = this.transPipe.transform('alert4');
    const confirmText = this.transPipe.transform('allertConfirmButton');
    Swal.fire({ titleText: alert4, text: alert3, icon: 'error', confirmButtonText: confirmText });
  }

  erroAlert(data) {
    const alert5 = this.transPipe.transform('alert5');
    Swal.fire(alert5, data, 'error');
  }
  alertOk(data) {
    this.language = localStorage.getItem("lang");
    const textDirection = this.language === "hebrew" ? "rtlClass" : "ltrClass"
    const popUp = Swal.mixin({
      customClass: {
        title: textDirection
      },
    })
    popUp.fire({
      position: 'center',
      icon: 'success',
      title: data,
      showConfirmButton: false,
      timer: 1500
    })
  }


  //   const ipAPI = '//api.ipify.org?format=json'

  // const inputValue = fetch(ipAPI)
  //   .then(response => response.json())
  //   .then(data => data.ip)
  passAlert() {
    Swal.fire({
      title: this.transPipe.transform('alert18'),
      input: 'text',
      showConfirmButton: true,
      inputValidator: (value) => {
        this.apiS.panelPass(value).subscribe(val => {
          if (val) {
            this.router.navigate(['/panel/']);
          }
        })
        if (false) {
          return '';
        }
      }
    })
  }


  erroAlertUserExiset() {
    const alert6 = this.transPipe.transform('alert6');
    const alert7 = this.transPipe.transform('alert7');
    const alert8 = this.transPipe.transform('alert8');
    const alert9 = this.transPipe.transform('alert9');

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: alert6,
      text: alert7,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#ffb74f',
      confirmButtonText: alert8,
      confirmButtonColor: '#0268aa',

      cancelButtonText: alert9,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.router.navigate(["login"])
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {

      }
    })

  }
  alertBeforeExam(data) {
    this.language = localStorage.getItem("lang");

    const textDirection = this.language === "hebrew" ? "rtlClass" : "ltrClass"
    const alert10 = this.transPipe.transform('alert10');
    const alert11 = this.transPipe.transform('alert11');
    const alert12 = this.transPipe.transform('alert12');
    const alert13 = this.transPipe.transform('alert13');
    const alert14 = this.transPipe.transform('alert14');

    const popUp = Swal.mixin({
      customClass: {
        content: textDirection,
        input: 'max-limit-custom'
      },

    })
    var inputValue
    popUp.fire({
      title: alert10,
      html: `${this.transPipe.transform('alert101')} <br/> <small class="small">* ${this.transPipe.transform('alert102')} </small>`,
      icon: "info",
      input: 'text',
      inputValue: inputValue,
      inputAttributes: {
        maxlength: '30',
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      showCancelButton: true,
      confirmButtonText: alert11,
      cancelButtonText: alert12,
      showLoaderOnConfirm: true,

      inputValidator: (value) => {
        if (!value) {
          return alert13;
        } else {
          this.apiS.saveExamName(value).subscribe(res => {
            if (res) {
              this.alertOk(alert14);
              setTimeout(() => {
                this.dataS.questionIdx = 0;
                this.router.navigate(['/exam']);
                this.dataS.examTime = true;
                this.dataS.examName = value;
                this.apiS.saveLog("start exam", new Date()).subscribe(res => {
                })
              }, 2000);
            }
          })
        }
      },
    })
    const inputEmail: HTMLInputElement = document.querySelector('.max-limit-custom');

    inputEmail.addEventListener("keyup", function (e) {
      const max = 30; // The maxlength you want
      if (inputEmail.value.length > max) {
        inputEmail.value = inputEmail.value.substring(0, max);
      }
    })
  }

  public alertLogout(): Promise<any> {
    const confirmText = this.transPipe.transform('alert15');
    const cancelText = this.transPipe.transform('alert16');
    const message = this.transPipe.transform('alert17');

    this.language = localStorage.getItem("lang");
    const textDirection = this.language === "hebrew" ? "rtlClass" : "ltrClass"
    const popUp = Swal.mixin({
      customClass: {
        title: textDirection
      },
    })
    return popUp.fire({
      position: 'center',
      icon: 'question',
      title: message,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
    })
  }
}
