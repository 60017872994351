import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
// import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
// import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        TranslatePipe
    ],
    imports: [],
    exports: [
        TranslatePipe
    ]
})
export class SharedModule { }