import { Component, OnInit } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import { language } from './app.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'quiz';

  ngOnInit() {    
    let lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", language);
    }
  }
}
