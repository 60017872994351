import { AlertSystemService } from 'src/app/alert-system.service';
import { ApiService } from 'src/app/services/api.service';
import { ApiResponse } from './../models/baseModels';
import { DataService } from 'src/app/data.service';
import { Component, OnInit, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '../translate.pipe';
import { language } from '../app.module';


@Component({
  selector: 'app-fromdata',
  templateUrl: './fromdata.component.html',
  styleUrls: ['./fromdata.component.css']
})
export class FromdataComponent implements OnInit {

  language;
  transPipe = new TranslatePipe();

  constructor(private dataS: DataService,
    private apiService: ApiService,
    private alertSystemService: AlertSystemService) { }

  title = "";
  subTitle = "";
  superContant = "";
  ngOnInit() {
    this.language = localStorage.getItem("lang");
  }


  sendData() {

    console.log(this.subTitle, this.superContant)
    this.apiService.sendEmailToOrna(this.subTitle, this.superContant).subscribe(res => {
      console.log(res);
      this.clear();
      const contactSuccessAlert = this.transPipe.transform('contactSuccessAlert');
      this.alertSystemService.alertOk(contactSuccessAlert);
      this.close();

    })

  }

  clear() {
    this.subTitle = "";
    this.superContant = "";
  }
  close() {
    this.dataS.modalForm = false;
  }

}

