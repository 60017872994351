import { Pipe, PipeTransform } from "@angular/core";
import { language } from "./app.module";
// import json from './languages.json';

@Pipe({
  name: "translate",
})
export class TranslatePipe implements PipeTransform {
  lang = {
    english: {
      allertConfirmButton: "Ok",
      alert1: "Success",
      alert2: "asd",
      alert3: "User name or password is incorrect",
      alert4: "Sign in",
      alert5: "Error",
      alert6: "Email address already exist",
      alert7: "Do you want to sign in?",
      alert8: "Sign in",
      alert9: "Cancel",
      alert10: "Name your test",
      alert101: "We recommand naming each test with a different name",
      alert102: "You can type up to 30 characters",
      alert11: "Continue",
      alert12: "Cancel",
      alert13: "Please name your test",
      alert14: "Ready? Here we go!",
      alert15: "Yes, logout",
      alert16: "No, return",
      alert17: "Are you sure you want to logout from the system?",
      contact1: "Contact us",
      contact2: "Subject",
      contact3: "Message",
      contact4: "Send",
      contactSuccessAlert: "Your message has been sent",
      demoInfo1: "Gender",
      demoInfo2: "Male",
      demoInfo3: "Female",
      demoInfo4: "Age",
      demoInfo5: "Under 18",
      demoInfo6: "Over 70",
      demoInfo7: "asd",
      demoInfo8: "asd",
      demoInfo9: "asd",
      demoInfo10: "asd",
      demoInfo11: "asd",
      demoInfo12: "asd",
      demoInfo13: "asd",
      demoInfo14: "asd",
      demoInfo15: "asd",
      demoInfo16: "asd",
      demoInfo17: "asd",
      demoInfo18: "asd",
      demoInfo19: "asd",
      demoInfo20: "asd",
      demoInfo21: "asd",
      demoInfo22: "asd",
      demoInfo23: "asd",
      demoInfo24: "asd",
      demoInfo25: "asd",
      demoInfo26: "asd",
      demoInfo27: "asd",
      demoInfo28: "asd",
      demoInfo29: "asd",
      demoInfo30: "asd",
      demoInfo31: "asd",
      demoInfo32: "asd",
      demoInfo33: "asd",
      demoInfo34: "asd",
      demoInfo35: "asd",
      demoInfo36: "asd",
      demoInfo37: "asd",
      demoInfo38: "asd",
      demoInfo39: "asd",
      demoInfo40: "asd",
      demoInfo41: "asd",
      demoInfo42: "asd",
      demoInfo43: "asd",
      demoInfo44: "asd",
      demoInfo45: "asd",
      demoInfo46: "Start the test",
      demoInfoAgeAlert: "asd",
      demoInfoRelAlert: "asd",
      demoInfoEmployAlert: "asd",
      demoInfoEducAlert: "asd",
      demoInfoStart: "asd",
      demoInfoTryAgain: "asd",
      head1: "Cancel and return to home screen",
      head2: "My test",
      head3: "Edit answers",
      exam1: "Accurate",
      exam2: "Unaccurate",
      finish1: "Good job",
      finish2: "You finished the test",
      finish3:
        "Would like to review your answers?",
      finish4:
        "press the menu button at the top left corner of the screen and update the answers",
      finish5: "After pressing the 'Results' button,",
      finish6: " you will not be able to retake the test",
      finish7: "Results",
      info1: "Development and regression",
      info2: "Helper",
      "info2-phrase": "The first one you give, the second you earn",
      "info2-description": `<h1>Helper</h1>

      <blockquote>
        <h2>The first one you give, the second you earn</h2>
      </blockquote>

      <h3>
        Key Characteristics
      </h3>
      <ul>
        <li>Friendly, caring and
          <strong>kind</strong>
        </li>
        <li>
          They are
          <strong>empathic</strong>,
          understanding and they have
          <strong>good listening abilities</strong>
          that make people trust and feel comfortable around them
        </li>
        <li>The Helper is very
          <strong>sensitive to the needs of others</strong>
          and tends to focus on fulfilling the needs and interests of other people. They are generous, even when not asked
          to, and often give others what they would like for themselves
        </li>
        <li>
          Usually accepts and agrees to demands and
          <strong>struggles to put boundaries</strong>
          and say “no”
        </li>
        <li>
          They put in a lot of effort into relationships, and believe, even if not consciously, that this is the only way
          people
          will love them. They believe that they need to give and sacrifice to be loved
        </li>
        <li>
          When not getting the
          <strong>appreciation</strong> and <strong>attention</strong>
          they crave for, Helpers are likely to feel like a victim, react in anger and use manipulative methods to gain
          control
        </li>
      </ul>

      <h3>
        Behavior in a negotiation process
      </h3>
      <p>
        Helpers have an intuitive understanding ability of their environment, allowing them to under-stand the situation in
        the
        negotiation process, for all its nuances, and show <strong>sensibility</strong> and <strong>kindness</strong>
        towards all the other participants. Nevertheless, their tendency to focus on interpersonal rela-tions might drive
        them
        away from their goals.
      </p>
      <p>
        They fear rejection and
        <strong>struggle to make boundaries</strong>,
        which often makes them do things against their will.
      </p>
      <p>
        They tend to please others and therefore might find themselves
        <strong>sacrificing their own interests</strong>
        for those on the side.
      </p>
      <p>
        People who belong to this type, expect others to recognize their needs and interests without having to tell them,
        like
        they do, and when it doesn’t happen, or even more than that, if they asked and were not answered, it will trigger
        resistance from their side towards the partici-pants, as well as towards the whole negotiation process, that would
        lead
        them to use manipu-lative methods to gain control.
      </p>
      <h3>
        Being a part of a negotiation team
      </h3>
      <p>
        The ‘Helper’ is a
        <strong>team player</strong>,
        mostly focused on<strong>interpersonal relations</strong>
        and cares about them as much as they care about the mission. They build and groom good pro-fessional relationships
        and
        support their team members. They have an intuitive ability to read people and situations and to understand what
        other
        people need so they can act for them be-fore even being asked to do so. They are also
        <strong>attentive, understanding, and trustworthy</strong>.
      </p>
      <p>
        Helpers need to feel appreciated for their doing and contribution to the team; they sometimes might even prefer a
        proper
        appreciation for their efforts over a materialistic reward. Nonethe-less, they keep up with people’s expectations
        and
        have <strong>difficulties to put boundaries</strong>.
        As long as they keep the balance they need and get attention and appreciation, they will be essential and
        contributing.
        However if the balance is broken, and they feel unappreciated for their efforts, they might get into an emotional
        turmoil and experience great frustration.
      </p>
      <h3>
        Questions that they should ask themselves before and during the negotiation
      </h3>
      <ul>
        <li>
          Am I taking care of my own interests as well?
        </li>
        <li>
          Am I over involved in other people’s lives?
        </li>
        <li>
          Is there an expectation for a reward behind my giving and contribution?
        </li>
        <li>
          Am I depended on the appreciation of others?
        </li>
      </ul>

      <h3>
        How do you identify resistance?
      </h3>
      <p>
        The Helpers need to be loved and appreciated makes them postpone and restrain their re-sistance. When resistance
        from
        their side does appear, it might be seen as a
        <strong>sudden change in their attitude</strong>,
        or as a very<strong>emotional and dramatic over-reaction</strong>.
        Sometimes, out of distress, they will try using
        <strong>manipulative methods</strong>
        as an attempt to get the appreciation they so desperately need.
      </p>
      <h3>
        How to negotiate with them?
      </h3>
      <p>
        Negotiating with a ‘Helper’ can be tricky and deceptive. Their kindness and generosity, along-side with their
        tendency
        to sacrifice, might be seen as altruism. But you should pay attention not to
        <strong>cross a thin line</strong>,
        which might make the ‘Helper’ feel used, and trigger dramatic responses of anger and domi-nance. </p>
      <p>
        The most important thing for the Helpers is to maintain a good relationship during the negotia-tion, even if it
        comes on
        the expense of some of their goals. Be aware of that, reflect your ap-preciation. It is important to
        <strong>show appreciation and gratitude</strong>
        for the Helpers efforts, and not take them for granted. Reflect to the Helpers that you can see their wills and
        needs,
        especially when they are concentrated on yours and
        <strong>show positive feelings</strong>.
        When you have to decline their request or resist to it,
        <strong>explain why and if possible, present an alternative solution</strong>.
        Thus, you will gain a real, empathic kind-hearted partner for the negotiation.
      </p>
      `,
      "info2-wings": `Helper at his best may adopt the
      <strong>Creative's </strong>,
      ability of self-expression while being aware of his emotions and desires, thus developing his unique self and unleashing the need for constant approval and acceptance from others. Adopting creative thinking patterns will help him balance his interests and those of others in negotiations.
      `,
      "info2-arrows": `Helpers may retreat towards the
      <strong>Boss </strong>
      type and exhibit aggressiveness toward his negotiation partners. In such a situation his giving and doing for others will be replaced by dominance and a desire to dictate the pace of progress of the negotiations to all.
      `,
      info3: "Performer",
      "info3-phrase": "I do, therefore I am ",
      "info3-description": `<h1>Performer</h1>

      <blockquote>
          <h2> I do, therefore I am </h2>
      </blockquote>

      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>
              <strong>Focused and effective</strong>.
              The Performer sets a target and pursues it at full speed, until achieved
          </li>
          <li>They have
              <strong>natural management abilities</strong>,
              good communication skills and an impressive ability to execute plans.
          </li>
          <li>They are
              <strong>competitive and achievement oriented</strong>.
              Performers are driven by the desire to succeed and be the best in their field. They are mo-tivated, act
              fast, not afraid
              of working under pressure and thrive in an achievement-oriented working environment
          </li>
          <li>
              Focusing mostly on their
              <strong>professional identity</strong>
              and maintaining a reputation of a winner. Performers believe that their value is deter-mined by their
              achievements and
              need appreciation and recognition from their surround-ings on what they have achieved.
          </li>
      </ul>
      <h3>
          Behavior in a negotiation
      </h3>
      <p>
          The Performers set goals and strives to achieve them. They will use their
          <strong>communication skills</strong>
          as well as their
          <strong>impressive and confident appearance</strong>,
          to bring the other side to an agreement as fast as possible. For them, the goal justifies the means
      </p>
      <p>
          They do not express emotions or weaknesses, but rather
          <strong>focus on the goals in front of them</strong>
          and expect others to behave in the same way.
      </p>
      <p>
          Performers prefer to
          <strong>determine the pace of the negotiation</strong>,
          and as long as they meet cooperation from the other side, Performers are highly motivated and willing to
          cooperate as
          well. However, slowing down and giving too much room for small details will challenge their patience and they
          might end
          up frustrated and quick tempered.
      </p>
      <h3>
          Being a part of a negotiation team
      </h3>
      <p>
          The performer will
          <strong>jump straight to the water, full of optimism</strong>
          and full belief that they can achieve their goal.
      </p>
      <p>
          Performers are
          <strong>natural managers</strong>,
          they will aspire to harness the team towards achieving the mutual goals and will expect them to remain motivated
          and
          goal oriented. It is meaningful for the performers to be appreciated by the team and to
          <strong>maintain their winner reputation</strong>.
          Performers
          <strong>value outcomes</strong>
          more than they value the process itself and tend to lose patience towards people who are slower than them, or
          towards
          people who ask for more details to push things forward.
      </p>
      <p>
          They
          <strong>perform well under pressure</strong>,
          and are very firm and competitive. They tend to avoid expressing emotions, or show weak-ness of any kind, out of
          a
          perception that it hurts their professional image.
      </p>
      <h3>
          Questions that they should ask themselves before and during negotiations
      </h3>
      <ul>
          <li>
              Is it important for me to win at all costs? </li>
          <li>
              Am I aware of my emotions and those of others in the negotiation? </li>
          <li>
              Am I seeing this as a conflict or as a cooperation? </li>
          <li>
              Am I prepared to accept other people’s opinions and offers? </li>

      </ul>

      <h3>
          How do you identify resistance?
      </h3>
      <p>
          The performers will tend to be
          <strong>impatient</strong>
          towards anyone who can’t keep up with their pace, in such a case they will struggle to listen, will reject those
          in
          front of them in a clear and direct way, sometimes even rudely.
      </p>
      <h3>
          How to negotiate with them?
      </h3>
      <p>
          Determine your goals and objectives in a
          <strong>clear way</strong>.
          Do your homework and
          <strong>come prepared</strong>
          with all the required
          <strong>professional knowledge</strong>
          .
      </p>
      <p>
          Try to keep up with
          <strong>their pace</strong>,
          and even try to be one step ahead of them.
          <strong>Avoid discussing personal matters</strong>and feelings if possible.
      </p>`,
      "info3-wings": `
      At his best, the Performer may embrace the
      <strong>Loyal skeptic's </strong>,
      habit, to pause the decision making a bit and carefully examine the options in front of him. He may moderate his natural determination and balance between intense doing and respite, which will allow him a deeper relation to his goals and improve his negotiation performance.
      `,
      "info3-arrows": `
      The Performer may retreat towards the
      <strong>Peacemaker </strong>
      type could bring him to a standstill and an inability to carry things out. He may be practical but unproductive and react out of anger and frustration to others which will disrupt his conduct in negotiations.
      `,
      info4: "Creative",
      "info4-phrase": "Life is a movie and I’m the main star",
      "info4-description": `<h1>Creative</h1>

      <blockquote>
          <h2>Life is a movie and I’m the main star</h2>
      </blockquote>
      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>Very empathic towards others,
              <strong>sensitive</strong>,
              and experience thing in a deep and meaningful way
          </li>
          <li>Individualist and
              <strong>Creative</strong>,
              they look for their own path to self-fulfillment
          </li>
          <li>
              They have a good and distinctive taste that often includes an authentic statement which reflects their
              abundant inner
              world.
          </li>
          <li>
              Creatives will perform best in an environment that enables them to express themselves.
          </li>
          <li>
              They are
              <strong>emotional</strong>
              and tend to react in quick emotional bursts
          </li>
          <li>
              Creatives
              <strong>need to be appreciated</strong> often.
              When it does not happen, they feel offended and might have a dramatic, and often an unappropriated response
          </li>
          <li>
              Romantic and tending to fall in love easily with people as well as with ideas. They
              <strong>require their emotional needs to be answered</strong>
          </li>
      </ul>
      <h3>
          Behavior in a Negotiation
      </h3>
      <p>
          Creatives bring their own unique perspective to the negotiation, as well as creative thinking and a lot of
          goodwill.
          They are <strong>empathic</strong> and very sensitive to the situation and pay
          <strong>attention to the details</strong>.
          They
          <strong>prefer to negotiate face to face</strong>,
          with a single person instead of a team. They will be in their peak performance as long as they feel emotionally
          satisfied
      </p>
      <p>
          Creatives tend to take things personally and therefore will <strong>struggle when facing criticism or
              resistance</strong>.
          In the Creatives’ eyes there is no professional criticism, everything is personal. Their response to criticism
          will
          often be dramatic and rough, but the drama will only last for a short while and they will be back to being
          caring and
          sensitive.
      </p>
      <h3>
          Being a part of a negotiation team
      </h3>
      <p>
          Creatives bring their
          <strong>unique and creative thinking</strong>
          to the team and aspire for significant doing. They need
          <strong>personal attention and appreciation</strong>
          for their work and creative ideas.
      </p>
      <p>
          The Creative is sensitive to the interpersonal relations within the group, values honesty, despise hypocrisy and
          frequently think about
          <strong>their sense of belonging to the team and their place in it</strong>.
          As long as they know what their place in the team is and they get to sound their unique voice and share their
          thoughts,
          they will act out of true sense of meaning and will find creative and original solutions.
      </p>
      <p>
          As individualists, Creatives will
          <strong>sometimes prefer to work alone</strong>,
          alone trying to avoid the complexity of interpersonal relations.
      </p>
      <h3>
          Questions they should ask themselves before and during negotiations
      </h3>
      <ul>
          <li>
              Am I managing my emotions in the negotiation or are they managing me?
          </li>
          <li>
              Can I create a delay between the stimulation and my response to it?
          </li>
          <li>
              Can I express my uniqueness and inspire others?
          </li>
          <li>
              Can I find self-esteem without depending on others?
          </li>
      </ul>
      <h3>
          How do you identify resistance?
      </h3>
      <p>
          When they express resistance, it is very hard to miss it, since they will often react with uncontrollable
          <strong>emotional bursts</strong>.
      </p>
      <p>
          They will often see criticism of their idea or request, as criticism against who they are. Therefore, their
          response
          might be trying to cut off the source of the criticism by
          <strong>distancing</strong>,
          themselves from that person.
      </p>
      <h3>
          How to negotiate with them?
      </h3>
      <p>
          Show
          <strong>interest and appreciation</strong>,
          to what they are doing and allow them to
          <strong>express themselves</strong>
          and their ideas. Compliment them for good doing and contributing.
      </p>
      <p>
          Never
          <strong>tell them to calm down</strong>,
          when they are having an emotional burst. Let the storm and wait for the Creatives to go back to being the
          interesting
          and creative person they are.
      </p>
      `,
      "info4-wings": `Creative at its best may adopt the
      <strong>Reformer’s </strong>
      ability to formulate internal meaning and personal responsibility and make dreams into productive endeavors. Growth towards the Reformer may also develop loyalty to his unique self and worldview that will be reflected in the negotiations.
      `,
      "info4-arrows": `
      Creative may retreat towards the
      <strong>Helper </strong>
      type and interpret opposition to his creative ideas as a lack of appreciation. In such a situation he will find himself frustrated and angry and struggle to cooperate in negotiations
      `,
      info5: "Investigator",
      "info5-phrase": "Every coin has two sides… at least",
      "info5-description": `<h1>Investigator</h1>

      <blockquote>
          <h2>Every coin has two sides… at least</h2>
      </blockquote>

      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>Investigators are rational, curious, intellectual and enjoy learning. They
              <strong>focus more on thinking instead of doing</strong>
          </li>
          <li>
              They have an ability to objectively observe and analyze different situations
          </li>
          <li>
              They experience the world with an
              <strong>intellectual perspective</strong>
              They watch, observe, aspire to understand while avoiding getting emotionally involved.
          </li>
          <li>They need time for themselves and
              <strong>stay private</strong>
          </li>
          <li>
              They tend to have social connections with only a small number of people and keep conversations short and to
              the point
          </li>
          <li>
              They are often
              <strong>restrained and under control</strong>
              are satisfied with a little, and appreciate those characteristics in others
          </li>
          <li>
              Investigators are sensitive, shy, and try to protect themselves from being emotionally harmed by keeping
              <strong>an emotional distance from others</strong>
              and by using rationally analyzing situations
          </li>
      </ul>
      <h3>
          Behavior in a negotiation
      </h3>
      <p>
          Investigators will see the negotiation as a research field, they will
          <strong>gather all the possible information</strong>,
          examine all the data, and
          ו<strong>would not rush into making a decision</strong>.
          Every new idea will have to go through the careful examination of the Investigators, and they will
          <strong>need more time</strong>
          making a decision.
      </p>
      <p>
          Once having all the details, they will
          <strong>prefer making the decision alone</strong>,
          according to their own criteria. They are not driven by a desire to please others, and will not back off when
          facing
          disagreement.
      </p>
      <p>
          Investigators will <strong>avoid exposing their emotions</strong>,
          and will do everything they can to stay calm and objective. Furthermore, they will
          <strong>avoid being emotionally involved</strong> and will find it hard to ask questions and get information in
          a way that could be seen as violating another person’s
          privacy. They might be seen as distanced or insensitive because of it, while actually being shy and vulnerable.
      </p>
      <h3>
          Being a part of a negotiation team
      </h3>
      <p>
          People who belong to the Investigator type will bring with them to the negotiations their
          <strong>analytical thinking</strong>,
          and their ability to examine the information
          <strong>patiently</strong> and
          <strong>objectively</strong>,
          as well as their tendency to dig deeper into the details and look for possible solutions. They will prefer to
          make a
          decision only after
          <strong>considering all other options</strong>,
          something that might prolong the duration of the negotiation and hold back agreements.
      </p>
      <p>
          Working in a team, they will prefer a
          <strong>clear assignment of the tasks and missions</strong>.
          They will focus on the mission under their responsibility and will invest great focus to that one task, even on
          the
          expense of their own needs.
      </p>
      <p>
          They tend to focus on the mission and not on the interpersonal relations between the
          team
          members,
          <strong>will almost never express emotions</strong>
          and will share things with only a handful of people with whom they feel safe. Often, they will look distanced
          from
          others, but this is their way to deal with their emotional and social difficulties.</p>
      <h3>
          Questions they should ask themselves before and during
      </h3>
      <ul>
          <li>
              Am I thinking too much about every matter?
          </li>
          <li>
              Am I distancing myself from others?
          </li>
          <li>
              Is it hard for me to make a decision under a pressure of time?
          </li>
          <li>
              Do I prefer to go over the details and make decisions alone?
          </li>
      </ul>
      <h3>
          How do you identify resistance?
      </h3>
      <p>
          Resistance can be caused by someone is
          <strong>doubting</strong>
          the Investigator’s analysis-based conclusions, when others are saying something or making a decision that the
          Investigators sees as <strong>senseless</strong> or <strong>unfounded</strong>,
          and mostly when they need to make decisions
          <strong>under time pressure</strong>.
          Likewise, a
          <strong>lack of clearness about the assignment</strong> and <strong>responsibilities</strong>
          in the team, can lead to resistance from the Investigators’ side.
      </p>
      <p>
          Investigators might take a step back and
          <strong>distance themselves</strong>.
          from the other side. They will
          <strong>cut all contact</strong>,
          trying to avoid an emotional breakdown and it will often be hard to understand the reasons for their behavior
          and
          identify them as a response to resistance or disagreement. Their reactions could be seen as distancing and being
          <strong>hard-headed</strong>,
          this may cause additional anger with the other side.
      </p>
      <h3>

          How to negotiate with them?
      </h3>
      <p>
          Talk in numbers and
          <strong>show them how you got to those numbers</strong>.
          Try to understand the way they look at things. Do not ask personal questions or over-share personal or emotional
          information with them.
          <strong>Give them time</strong>
          to examine the details and analyze them, and when possible,
          <strong>give them some space</strong>.
          Understand that what might seem to be distancing or a lack of cooperation from their side, is actually meant to
          cover
          for being sensible and vulnerable.
      </p>
      `,
      "info5-wings": `The Investigator at his best can embrace the
      <strong>Boss’s </strong>,
      ability to be a visionary and lead pioneering thinking. He will leverage the learning and research that he already does very well, into new actions and advancing the negotiation.
      `,
      "info5-arrows": `
      The Investigator might retreat towards an
      <strong>Adventurer </strong>,
      type, in such a situation he would find himself jumping from one thought to another, unable to focus and move towards making a decision. It will be very easy to divert him to marginal matters during the negotiations and he will feel paralyzing instability.
      `,
      info6: "Loyal Skeptic",
      "info6-phrase": "If something can go wrong, it will",
      "info6-description": `<h1>Loyal Skeptic</h1>
      <blockquote>
          <h2> If something can go wrong, it will</h2>
      </blockquote>
      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>
              Responsible,
              <strong>dedicated and committed</strong>,
              trustworthy, and loyal to people and to the mission
          </li>
          <li>They
              <strong>see the world as an unsafe place</strong>,
              and environment you cannot trust, and therefore create a safety net for themselves and always prepare for
              the worst
              scenario
          </li>
          <li>
              They doubt everything, especially themselves. Fears failure and prefer the familiar rather than the unknown
          </li>
          <li>Skeptics are
              <strong>calculated and careful</strong>,
              tend to play it safe and to avoid taking risks
          </li>
          <li>
              Skeptics will
              <strong>have a hard time making decisions</strong>
              and will carefully examine all possible risks
          </li>
      </ul>
      <h3>
          Behavior in a negotiation
      </h3>
      <p>
          Skeptics will bring with them to the negotiation their biggest doubts and their need to
          <strong>double-check everything and look for hidden intentions</strong>
          in what the other’s side is saying. They find it hard to trust others, and even more, they struggle trusting
          themselves,
          therefore they <strong>always prepare for the worst</strong>.
          However, the Loyal Skeptic is
          <strong>decent, responsible and can be trusted</strong>
          to keep up with their word.
      </p>
      <p>
          Their need to carefully
          <strong>check all the alternatives and all possible scenarios</strong>,
          might prolong their decision-making process and make the other side of the negotiation doubt their intentions.
      </p>
      <h3>
          Being a part of the negotiation team
      </h3>
      <p>
          Skeptics
          <strong>feel secured</strong>,
          when they are a part of a team, they have natural social skills that help them when working as a part of a
          team. They
          show <strong>responsibility and commitment</strong>
          commitment to the success of the team.
      </p>
      <p>
          In addition to that, they tend to be too careful and
          <strong>struggle when making decisions</strong>.
          Their indecision and their difficulty in taking responsibility and make a move, along with their excessive focus
          on
          difficulties instead of opportunities, might cause negative team dynamics when the rest of the team wants to
          move on and
          make progress with the negotiation. On the other hand, when the Skeptics feel
          <strong>appreciated and liked</strong>
          within the team, they will also, as a result, feel confident and will be able to contribute their important
          insights to
          the shard mission.
      </p>
      <h3>
          Questions they should ask themselves before and during the negotiation
      </h3>
      <ul>
          <li>
              Is the worst scenario I have in my mind even realistic?
          </li>
          <li>
              Are my concerns exaggerated?
          </li>
          <li>
              Can I find ways to decrease the stress I am feeling about the negotiation and its results?
          </li>
          <li>
              Will breaking down a big decision into smaller decisions help me make progress?
          </li>
      </ul>
      <h3>
          How do you identify resistance?
      </h3>
      <p>
          Skeptics will show resistance
          <strong>when things are not clear for them</strong>.
          Uncertainty, opacity, and feeling that the person on the other side is being reckless and not taking the
          Skeptic’s
          doubts seriously, will all lead to resistance from their side. Along with that, demanding Skeptics to make a
          decision
          when not having enough time to go through every alternative will also lead to resistance.
      </p>
      <p>
          They tend to
          <strong>distance themselves</strong>
          and show pessimism and criticism as an expression of their resistance.
      </p>
      <h3>
          How to negotiate with them?
      </h3>
      <p>
          Never tell a Skeptic that things are “going to be ok…”, instead,
          <strong>show them exactly how the problem will be solved</strong>.
          Give them
          <strong>every piece of information</strong>
          you have, be
          <strong>direct and open</strong>,
          and create a safe environment for them to operate within.
      </p>
      <p>
          <strong>Show appreciation</strong>
          for their responsibility and devotion.
          <strong>Encourage</strong>
          them to make decisions, but
          <strong>do not criticize</strong>
          their stress or anxiety.
      </p>
      `,
      "info6-wings": `
      The Loyal Skeptic at his best may adopt the
      <strong>Peacemaker’s </strong>,
      ability to listen to the negotiating parties and cooperate out of his natural loyalty and responsibility characteristics, which will help him gradually develop trust in others and lead negotiation processes.
      `,
      "info6-arrows": `
      The Loyal Skeptic might retreat towards the
      <strong>Performer </strong>
      type and react irrationally, accusing others for the lack of progress in negotiations and acting very combatively.
      `,
      info7: "Adventurer",
      "info7-phrase": "You only live once",
      "info7-description": ` <h1>Adventurer</h1>
      <blockquote>
          <h2> You only live once</h2>
      </blockquote>

      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>
              Friendly, optimistic, outgoing, charismatic, and spontaneous
          </li>
          <li>The Adventurer needs
              <strong>freedom and space</strong>,
              they are in constant motion and have
              <strong>difficulty focusing on one thing</strong>
          </li>
          <li>
              The Adventurer’s worldview motivates them to seek thrills and pleasures and to avoid suffering and pain
          </li>
          <li>
              Their movement forward is physically and mentally, freedom of movement also includes the ability to move
              from one idea
              to another and actualize its
              <strong>abundance of ideas</strong>
          </li>
          <li>
              They connect easily with others, has
              <strong>good expressive and persuasive abilities</strong>,
              they look on the possibilities in an optimistic way and sweeps others with their enthusiasm
          </li>
          <li>
              They might be impatient, expect immediate results, and lack internal discipline
          </li>
      </ul>
      <h3>
          Behavior in a negotiation
      </h3>
      <p>
          The Adventurer enters to the negotiation in an optimistic and enthusiastic manner. They are
          <strong>are full of ideas, and jump from one idea to another</strong>
          without checking the basis of them to the end. Difficulties do not scare them, they always see the bright side
          of things, and
          <strong>recognize a crisis as an opportunity</strong>.
      </p>
      <p>
          Their will to move forward and finish the negotiation in order to move to the next thing, might cause decision
          making without checking the basis of the things and taking risks.
      </p>
      <h3>
          Being a part of a negotiation team
      </h3>
      <p>
          Most of the times, Adventurers will be the spirit of the team. They create an
          <strong>easy to handle environment</strong>
          and infects others with his enthusiasm. They are able to move from one subject to another and inte-grate between
          different domains. They contribute
          <strong>innovation and original thinking</strong>
          to the team, and do not fear to implement them and to persuade others to join them. They have no interest in
          hierarchy and authority and
          <strong>sees all as equal</strong>.
          It is easy for them to act when being a part of a team, as long as their freedom is being kept.
      </p>
      <p>
          Adventurers
          <strong>express their opinion openly and decisively</strong>,
          sometimes impatiently while rejecting other opinions. They believe that their perspective on the situ-ation is
          the most correct and finds it
          <strong>hard to accept other opinions</strong>.
          They have hard time taking responsibility for failure, they tend to blame others, rationalize the event, or
          downplay its importance.
      </p>
      <h3>
          Question that should be asked before and after negotiations
      </h3>
      <ul>
          <li>
              Does my lack of focus prevent me from finishing tasks?
          </li>
          <li>
              Do I tend to take unnecessary risks?
          </li>
          <li>
              Is my assessment based on objective data?
          </li>
          <li>
              How can I implement my ideas while delegating powers to others?
          </li>

      </ul>
      <h3>
          How do you identify resistance?
      </h3>
      <p>
          The Adventurer will show resistance when
          <strong>his freedom</strong>,
          is being limited, physically and mentally. When asked to
          <strong> complete a task or to keep up with stiff schedules</strong>.
          When resisting, they might be impulsive.
      </p>
      <p>
          They will lose their patience, blame the results on others fault, and will create a reality picture in which the
          event
          is unimportant, or that the mistake is others fault, and they will anyway find rational expla-nations to the
          failure.
      </p>
      <h3>
          How to negotiate with them?
      </h3>
      <p>
          Dealing with an Adventurer, requires
          <strong>keeping up with their high pace</strong>,
          and understanding that as long as they are having a good time, they will be persistent in the negotia-tion. They
          focus
          on pleasure and find it tough to listen to others’ troubles, which is why it is best not to overload them with
          information. To make them cooperate, you should keep a positive spirit and
          <strong>talk about the benefits and enjoyments</strong>
          of both sides gaining from the deal.
      </p>
      <p>
          It is important to let them come up with
          <strong>ideas and creative solutions</strong>,
          but not to expect them to get into the small details involved in making the idea a reality. The Adven-turer is
          an
          <strong>entrepreneur</strong>,
          not an executer. You will have to give them the
          <strong>operating space and the flexibility</strong>,
          they need.
      </p>
      `,
      "info7-wings": `An Adventurer at his best may embrace the
      <strong>Investigator’s </strong>,
      ability to investigate and examine things in depth, and gather the information required before making a decision. He will be able to balance his passion to act and respond impulsively and will be able to conduct the negotiations with less distractions.
      `,
      "info7-arrows": `An Adventurer may retreat towards the
      <strong>Reformer </strong>
      type and find himself acting rigidly, unable to hear other opinions. He may lose his flexibility and optimism and negotiate in a non-goal-oriented way, sometimes under the guise of principles and values.
      `,
      info8: "Boss",
      "info8-phrase": "If you’re not with me, you’re against me",
      "info8-description": `<h1>Boss</h1>
      <blockquote>
          <h2> If you’re not with me, you’re against me </h2>
      </blockquote>
      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>The Boss is a
              <strong>born leader</strong>,
              direct, charismatic and holds a powerful presence.
          </li>
          <li>According to their worldview there are only two options: to be strong or weak, winner or loser. Therefore,
              they strive
              to demonstrate
              <strong>control and power</strong>
              and recoil from the presence of injury and weakness
          </li>
          <li>
              They express their opinion openly and decisively, are self-confident, do not avoid difficulties nor do they
              fear
              confrontation
          </li>
          <li>
              They see justice as an important value and are ready to fight and protect the weak and exploited
          </li>
          <li>
              Appreciate integrity and respect,
              <strong>avoids emotional gestures</strong>
              hypocrisy, and pretension
          </li>
      </ul>
      <h3>
          Behavior in a negotiation
      </h3>
      <p>
          Bosses negotiates with full power. They
          <strong>do not fear confrontations</strong>
          and will fight for their opinion, even if the one in front of them has a higher authority than they have. They
          <strong>will express their opinion in a direct way</strong>
          and will cooperate with whoever agrees with them. The ones who will disagree with a Boss type, will find
          themselves very
          quickly in an open and visible confrontation against them.
      </p>
      <p>
          It is important for them to conduct the negotiation with
          <strong>integrity</strong>, they response in anger to hypocrisy and
          pretension.
          Highly value a direct approach and are
          <strong>are impatient for a show of weakness and emotionality</strong>, in others
      </p>
      <p>
          The Boss is committed to the process and does all they can to promote it. Nonetheless, they
          <strong>they expect immediate results</strong>,
          results and will be sometimes impatient for long and complicated processes.
      </p>
      <h3>
          Being a part of a negotiation team
      </h3>
      <p>
          The Boss is the team’s engine, they
          <strong>push, cheer and motivate</strong> for action.
          They are natural leaders that nothing stands in their way. They will fight for all those they see as allies and
          on the
          contrary, will not hesitate to harm those who stand against them.
      </p>
      <p>
          They will express his opinion decisively and will find it difficult to listen to other opinions. Interpersonal
          relations
          are not a goal for them, they
          <strong>struggle with expressing emotions and vulnerability</strong>,
          and focus on the tasks ahead. They
          <strong>do not fear confrontation</strong>
          between team members, as long as they are promoting the cause, and essentially, as long as they are in control
          of
          everything.
      </p>
      <p>
          The Boss
          <strong>expects to have all the necessary information</strong>,
          to promote the negotiation. They expect the team members to act by their standards, openly and with integrity.
          When that
          doesn’t happen, they will respond in anger and sometimes in revengeful-ness.
      </p>
      <h3>
          Question that should be asked before and after negotiations
      </h3>
      <ul>
          <li>
              Do I go into unnecessary confrontations?
          </li>
          <li>
              Do I allow others to express their opinion?
          </li>
          <li>
              Does my approach avoid others to hand me essential information?
          </li>
          <li>
              Are there other ways, except mines, to promote the negotiation?
          </li>
      </ul>
      <h3>
          How do you identify resistance?
      </h3>
      <p>
          Bosses will resist when they notice
          <strong>hypocrisy or pretension</strong>,
          when people
          <strong>do not take responsibility</strong>
          for their actions, and when
          <strong>hurting weaker ones</strong>.
      </p>
      <p>
          The resistance of Bosses is
          <strong>open and powerful</strong>.
          Their anger burns and shreds all in their way. They will express resistance in a
          <strong>direct way</strong>,
          with<strong>great anger</strong>
          and sometimes in
          <strong>disrespect</strong>.
      </p>
      <h3>
          How to negotiate with them?
      </h3>
      <p>
          Bosses demand from others what they demand from their selves.
          <strong>Act respectfully</strong>,
          put your cards on the table and be
          <strong>open and direct</strong>.
          Conduct the negotiation with
          <strong>integrity</strong>
          and avoid emotionality and emotional exposure.
      </p>
      <p>
          When Bosses are angry,
          <strong>take a step backwards, but do not vanish</strong>,
          let them calm down and go back to talk with them practically. A show of fear or rage will only feed the flames.
      </p>
      <p>
          Be aware of their will to close a deal and get fast results, when it is a common goal, create terms that will
          allow it.
          Remember that they
          <strong>appreciate results and partners who perform under their standards</strong>
          and rates.
      </p>
      `,
      "info8-wings": `
      The Boss at his best can embrace the
      <strong>Helper’s  </strong>,
      ability, to naturally lead, out of listening, generosity and caring for others. He may moderate his tendency to act alone and take responsibility, encourage his partners and help them get through crises and move from conflicts to consensual negotiations.
      `,
      "info8-arrows": `
      Boss may retreat towards the
      <strong>Investigator </strong>,
      type, in which case he will want to control his environment by obsessive information collecting. He will tend not to share information with others and will negotiate with cards close to his chest. He might trigger rivalries and may even use threats and revenge.
      `,
      info9: "Peacemaker",
      "info9-phrase": "Sitting on the sideline… okay with everyone",
      "info9-description": `<h1>Peacemaker</h1>
      <blockquote>
          <h2> Sitting on the sideline… okay with everyone </h2>
      </blockquote>
      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>Kind-hearted,
              <strong>sensitive</strong>,
              containing and
              <strong>harmoniously integrates</strong>
              with his environment
          </li>
          <li>
              Their worldview guides them to
              <strong>avoid confrontations</strong>,
              keep
              <strong>quiet</strong>
              and soak up a
              <strong>pleasant atmosphere</strong>
          </li>
          <li>
              They tend to cooperate with others, are
              <strong>patient and attentive</strong>,
              but do not openly express their interests, and often suppress their own wishes
          </li>
          <li>
              The Peacemaker tends to paint reality in a positive light, sometimes, while ignoring problems. They rather
              distract
              themselves by tempering with other things and tend to procrastinate when they have work to be completed
          </li>
      </ul>
      <h3>
          Behavior in a negotiation
      </h3>
      <p>
          Negotiating is neither a simple nor a natural situation for Peacemakers. The requirement to take a stand and
          express
          their thoughts challenges them. They are
          <strong>willing to accept different opinions</strong>
          and will be patient and nice, as long as it is not necessary to get out of their inner balance and confront the
          other
          side. They would rather listen to the other side’s demands and
          <strong>settle, even at the cost of giving up their own interests</strong>.
      </p>
      <p>
          The
          <strong>need of making a decision</strong>,
          under
          <strong>time pressure</strong>,
          is difficult for them and might cause them to show resistance. Often, they will start a negotiation, without
          even
          defining to themselves what are their interests and goals.
      </p>
      <h3>
          Being a part of a negotiation team
      </h3>
      <p>
          The Peacemaker brings a
          <strong>mediator</strong>.
          perspective to the team. They can see both sides of a negotiation and understands them, as long as they can
          avoid taking a stand. They have a way
          to minimizing confrontations inside the team, and to
          <strong>bridge the gaps in the stands</strong>,
          to create
          <strong>common ground and to unite</strong>
          the team members, to achieve the goal. They
          <strong>enjoy teamwork</strong>,
          as long as everything is under control.
      </p>
      <p>
          They are not competitive and therefore it is easy and natural for them to be a part of a team. That be-ing said,
          they
          are not the person who will push the team towards the goal, especially if the result in-volves changes that
          would get
          them out of their comfort zone. They usually avoid expressing their opinion and find it
          <strong>difficult to take a clear stand</strong>.
          They
          <strong>need time</strong>
          to learn about the conflict from all of its angles and
          <strong>tend to ignore what bothers them</strong>.
          which makes them be perceived as nice and comfortable but passive and obvious.
      </p>
      <h3>
          Question they should ask themselves before and after negotiations
      </h3>
      <ul>
          <li>
              Do I tend to ignore problems?
          </li>
          <li>
              Am I playing my opinion in negotiations or just listening to others?
          </li>
          <li>
              Do I get caught for granted?
          </li>
          <li>
              Do I tend to procrastinate and the tasks are piling up on my desk?
          </li>
      </ul>
      <h3>
          How do you identify resistance?
      </h3>
      <p>
          A demand
          <strong>to take a clear stand, especially in pressured times</strong>,
          might cause the Peacemaker to resist.
      </p>
      <p>
          The first and most common expression of resistance would be to
          <strong>shut in</strong>.
          They will try hiding their anger from sight. The more their inner resistance will be, especially when they would
          be
          spoken to rudely, and get cornered, they might be passive and apathetic, or aggressive and impatient.
      </p>
      <h3>
          How to negotiate with them?
      </h3>
      <p>
          Peacemakers will prefer to stay in their
          <strong>safe environment</strong>,
          any requirement to leave it is undermining their
          <strong>internal balance</strong>.
          Set shared schedules, but
          <strong>avoid pressuring them, or to express criticism</strong>
          that they do not meet the schedules.
      </p>
      <p>
          Be attentive to them, appreciate their ability to contain, but do not take advantage of them.
          <strong>Speak with them pleasantly</strong>
          and try to reach a result that will manifest the interests of both sides. Lead the negotiation to an
          <strong>agreement</strong>,
          the Peacemaker is not a leader, however,
          <strong>they greatly value cooperation</strong>.
      </p>
      `,
      "info9-wings": `
      The Peacemaker at his best can adopt patterns of the
      <strong>Performer </strong>
      type and better deal with the fear of conflicts, create harmony between individuals and groups in negotiations, and bring them closer towards agreements and cooperation.
      `,
      "info9-arrows": `
      The Peacemaker may retreat towards the
      <strong>Loyal Skeptic </strong>
      type and find himself completely abstaining from expressing a position and dealing with conflicts in negotiations, to the point of suppressing his desires, causing detachment and even dysfunction.
      `,
      info10: "Reformer",
      "info10-phrase": "My way or the highway",
      "info10-descripton": `<h1>Reformer</h1>

      <blockquote>
          <h2>My way or the highway</h2>
      </blockquote>
      <h3>
          Key Characteristics
      </h3>
      <ul>
          <li>They are reliable, idealist, trustworthy and dedicated. </li>
          <li>They have <strong>high levels
                  self-discipline</strong> and aspiration
              to live
              life in their own way</li>
          <li>בעל
              Reformer's <strong>perfectionism</strong> guides them to complete any task seriously, loyally, with no
              compromises and while
              keeping up
              with the <strong>high standards</strong> they set for themselves.
          </li>
          <li>The Reformer is a <strong>dedicated and trustworthy</strong> companion/partner; they can be trusted and are
              willing to take
              sacrifices
              for their mission and for their partners.
          </li>
          <li>Diligent and hard-working, and has a natural ability to manage tasks and complete them
          </li>
          <li>Out of an inner belief that <strong>there is always room for improvement</strong>, the Reformer will
              criticize themselves,
              and <strong>demand
                  their surroundings to keep up with their standards</strong> as well.
          </li>
      </ul>
      <h3>
          Behavior in a negotiation
      </h3>
      <p>
          The Reformer enters a negotiation with a <strong>clear goal, an organized plan</strong> of action and their own
          perception of how
          things
          should be happening. As long as the process is conducted according to their principles, they will cooperate. If
          the
          motives of the other side in the negotiation are not aligned with the <strong>Reformer’s ethical
              standards</strong> and life
          perspective
          he will show resistance and will find it <strong>hard to compromise</strong>.
      </p>
      <p>
          As a perfectionist they need <strong>clear and organized information</strong> that includes all relevant
          details. They find it
          important
          to know the agenda, what each side’s interests are and what is expected to happen in every stage of the process.
          They
          also require time to prepare and therefor value <strong>early preparations and keeping up with
              schedules</strong>.
      </p>
      <p>
          During the negotiation process, they tend to <strong>stick to the patterns and beliefs</strong> they are
          familiar with and find
          it hard
          to keep an open mind and accept new ideas and solutions. In case of unexpected changes, they will <strong>need
              time</strong> to
          accept
          the new reality. <strong>If they feel they lose control</strong> and things don’t work out the way they planned
          them, they will
          start
          showing resistance by expressing criticism and judgment towards others. They will tend to focus on the wrong or
          lacking
          instead of focusing on the possible gain from the process; thus, they may be seemed as strict and stiff-necked.
      </p>
      <h3>Being a part of a negotiation team </h3>
      <p>
          They are <strong>responsible, trustworthy, and highly organized</strong>. The reformer’s life perspective often
          makes them lead
          the
          negotiation, even if not officially. They have high organizing abilities and will take on multiple tasks while
          sparing
          no work and responsibility from themselves. Their ability to control the details often makes it <strong>hard for
              them to
              delegate</strong> to others.
      </p>
      <p>
          They will <strong>aspire for perfection</strong> and constantly look for how can the process be improved and how
          can better
          results be
          attained and will require those around them to keep up with the same standards. The very clear path of the
          Reformers to
          themselves is not always understood for those around them. By those looking from aside this may seem Like
          unnecessary
          and troublesome insistence on details, instead of moving forward with the things at hand.
      </p>
      <p>
          Reformers, being used to <strong>be in control</strong> of all the details, will sometimes feel anger and hold
          grudges towards
          other
          people when exterior circumstances disturb the process.
      </p>
      <p>
          The Reformer’s perfectionism might lead to procrastination and difficulty with keeping up with deadlines.
          <strong>They
              need time</strong>
          to do things in their way, something that could cause anger when there is a deadline or pressure to close a
          deal/agreement and the team looks to move forward.
      </p>
      <h3> Questions that they should ask themselves before and during the negotiation </h3>
      <ul>
          <li>
              Is my way the only way?
          </li>
          <li>
              Is there anything I can get, without giving up on my integrity? </li>
          <li>
              Do I prefer being right or effective? </li>
          <li>
              What might I miss when I insist on doing things my way? </li>
      </ul>

      <h3>
          How do you identify resistance?
      </h3>
      <p>
          The Reformer often tends to repress their anger, because they see it as an unwanted emotion.
      </p>
      <p>
          Their conflict/resistance will be expressed as <strong>unrest, Impatience, frustration, and judgment</strong>
          towards others. In
          such a
          case, they are <strong>on the edge of explosion</strong> and the slightest thing might cause them to burst, be
          mad at themselves
          for
          doing so and later comes the feeling guilt.
      </p>
      <p>
          The reformer’s resistance will mostly be expressed by criticism and judgment towards others as well as by
          focusing on
          the wrong and lacking, instead of focusing on the possible gains.
      </p>
      <h3>
          How to negotiate with a Reformer?
      </h3>
      <p>
          To have the Reformer on your side while negotiating, it is important to deal with the Reformer with
          <strong>fairness and
              dignity</strong>, build <strong>trust</strong> with them from the start. If they feel you can be trusted, it
          will be much
          easier for them
          to lose
          their grip on the details and move forward with the process.
      </p>
      <p>
          They find it very important to achieve the goals they set for themselves, show <strong>honest interest</strong>
          in their
          interests and
          try finding, together, <strong>creative ways to expand the shared pie</strong>, when you make an offer, make
          sure it is <strong>as much
              detailed
              and accurate as possible</strong>. Controlling all the details is important to them and helps them make sure
          they are
          moving
          ahead according to the goals and the path they planned.
      </p>
      <p>
          The Reformer will inspect and criticize themselves all the time. They see self-criticism as way to improve, and
          to keep
          up with the high standards they set for themselves. Never the less they are very <strong>sensitive to criticism
              from
              others
              against them</strong>. When a Reformer criticizes you, try accepting their criticism as a part of the
          process they
          experience and
          do not rush to take things personally. Overcome the natural feeling of anger and try understanding what really
          bothers
          them and how it can be solved.
      </p>
      `,
      "info10-wings": `At his best, the Reformer can embrace the
      <strong>Adventurer's </strong>,
      ability to explore new possibilities and opportunities, show flexibility and let go of his stiffness and fixations. He will be able to lead negotiation processes, out of non-judgmental listening and self-criticism that will integrate with his pursuit of excellence
      `,
      "info10-arrows": `The Reformer may retreat toward the
      <strong>Creative </strong>
      type, in this situation his assertiveness may become judgmental and impatient criticism of others, he will exhibit anger and stiffness and he will find it difficult to find partners and reach agreements in negotiations
      `,
      info11PopUp: "Each type can move toward the behavior patterns of two other types",
      info12PopUp: "Development arrow",
      info13PopUp:
        "Shows our potential to develop and reach a balance and utilization of our abilities, when we are at our best and act out of awareness and desire to improve",
      info14PopUp: "Regression arrow",
      info15PopUp:
        "Presents our ability to retreat to behavioral patterns that challenge and distance us from utilizing our abilities, when we are not at our best or in stressful situations",
      log1: "Users",
      log2: "Logs",
      log3: "coupons",
      log4: "Logs table",
      log5: "Total Registered",
      log6: "Search",
      log7: "Kind",
      log8: "תוכן",
      log9: "User id",
      log10: "Email",
      log11: "Date",
      login1: "Been here before",
      login2: "New here",
      login3: "Sign in",
      login4: "Conflicts managing system",
      login5: "Email",
      login6: "Password",
      login7: "Sign in",
      login8: "Forgot password",
      loginMailSentAlert: "We sent you a password-reset email",
      loginMailAlert: "There was an error with sending the email",
      myExams1: "Discount",
      myExams2: "How to approch the test?",
      myExams4: "asd",
      myExams5: "My tests",
      myExams6: "New test",
      myExams7: "Click to purchase",
      myExams8: "Handling conflicts",
      myExams9: "Start a new test",
      myExams10: "Test results",
      myExams11: "The test includes 54 statements",
      myExams12: "For each statement, choose whether it's",
      myExams12represents:
        " accurate for you",
      myExams12notRepresents:
        "or not",
      myExams13:
        "During the time of the test you can always go back and change your choices.",
      myExams14: "With that said,",
      myExams15: "it's very important that you answer intuitively",
      myExams16: "so the results will be accurate to you.",
      myExams17: "When taking the test, try focusing on",
      myExams18: "one aspect only from the two bellow:",
      myExams19: "You can focus on the",
      myExams20: "proffesional",
      myExams21:
        " aspect (your behavior in workplace, with colleagues, while purchasing a car / apartment etc.)",
      myExams22: "Or",
      myExams23: "You can focus on the ",
      myExams24: "personal",
      myExams25:
        "aspect (your behavior with your parants, children, family members, friends etc.)",
      panela1: "Users",
      panela2: "Logs",
      panela3: "Coupon",
      panela4: "Total",
      panela5: "Add a new coupon",
      panela6: "Name",
      panela7: "Package",
      panela8: "Discount",
      panela9: "Usage",
      panela10: "Applied",
      panela11: "Experation date",
      panela12: "Actions",
      panela13: "Delete",
      panelaSuccessAlert: "Success",
      panelaProblemAlert: "There was an error",
      panelModal1: "Coupon's name",
      panelModal2: "Package",
      panelModal3: "Total coupons",
      panelModal4: "Experation date",
      panelModal5: "Discount",
      panelModal6: "Save",
      panelModalPack: "General discount",
      purchase1: "Your order",
      purchase2: "Discount",
      purchase3: "Choose a package",
      purchase4: "Coupon code",
      purchase5: "Activate coupon",
      purchase6: "Invalid coupon",
      purchase7: "I agree to terms of use",
      purchase8: "Price",
      purchase9: "Checkout",
      purchase10: "Total price",
      purchase11: "You have not approved the terms of use",
      purchaseYouGot: "Hey, you have",
      purchaseDiscount: "precent discount on",
      purchaseCupon: "Coupon Successfully registered",
      purchaseSuccess: "Purchased Successfully",
      purchaseNewPurchase: "Purchase a new test\n",
      purchasePrice: "at price",
      purchasePack1: "One test",
      purchasePack2: "Two tests",
      purchasePack3: "Test + Consulting",
      register1: "Sign up",
      register2: "Name is required",
      register3: "First and last name",
      register4: "Email is required",
      register5: "Email address",
      register6: "Confirm password",
      register7: "Invalid email address",
      registerVelidatePassword: "Password is required",
      registerVelidatePasswordConfirm: "Confirm password is required",
      registerWrongMailAlert: "Invalid email address",
      registerPassAlert: "Passwords don't match",
      registerConfirmAlert: "Success, we have sent you a confirmation email",
      registerTryAgainAlert: "We encountered an error, please try again",
      title0: "שאלון אבחון - פרטים דמוגרפיים",
      title1: "My test results",
      title2: "Tests - ",
      title3: "Account details",
      results1: "My test results",
      results2: "asd",
      results3: "More about the types",
      results4: "לאבחון אחד על אחד",
      results5: "You finished the test",
      results6:
        "Your result is unique only to you and shows the mix of your personal types, in the aspect you have chosen to relate to (professional / personal). There is no one else who is exactly like you!",
      results7:
        "It is important to remember that there is no good and bad, so there is no need to be judgmental. Just understand the special mix of your types and how this mix can help you in subsequent negotiations.",
      results8:
        "Each of us has some dominant types (rating above 4) and some less so (rating below 4).",
      results9:
        "It is advisable to start reading about your dominant types to understand the main motives for your opposition in negotiations and in life in general.",
      results10:
        "Clicking on 'More about the types' will take you to an extension on the different types.",
      results11:
        "You can navigate between the different types by clicking on the name of the type in the wheel or using the navigation bar on the hand right.",
      results12:
        "Have you gone through the information of all the types and still have a taste for more?",
      results13: "You can always",
      results14:
        "and order personal counseling that will focus on the results of your test.",
      results15: "contact us",
      slideshow: "Negotiation managment in all life's areas",
      userDetails1: "Hello",
      userDetails2: "Account details",
      userDetails3: "First name",
      userDetails4: "Last name",
      userDetails5: "Email address",
      userDetails6: "Update details",
      userDetails7: "Reset password",
      userDetails8: "Current password",
      userDetails9: "New password",
      userDetails10: "Confirm new password",
      userDetails11: "Update password",
      userDetailsChangesSuccessAlert: "Account details changed",
      userDetailsProblemAlert:
        "We encountered an error, please try again later",
      userDetailsPassAlert: "Passwords don't match",
      userDetailsChangePassAlert: "Password changed",
      userDetailsTryAgainAlert:
        "We encountered an error, please try again later",
    },
    hebrew: {
      allertConfirmButton: "הבנתי",
      alert1: "בוצע בהצלחה",
      alert2: "הייתה בעיה",
      alert3: "שם המשתמש או הסיסמה לא נכונים",
      alert4: "התחברות",
      alert5: "שגיאה",
      alert6: 'כתובת הדוא"ל שהוזנה קיימת כבר במערכת',
      alert7: "האם תרצה להתחבר ?",
      alert8: "עבור להתחברות",
      alert9: "ביטול",
      alert10: "הזינו שם לאבחון",
      alert101: "בחרו שם שונה לכל אבחון",
      alert102: "ניתן להקליד עד 30 תווים",
      alert11: "המשך לבחינה",
      alert12: "ביטול",
      alert13: "חייב להכניס שם לאבחון",
      alert14: "מוכנים? בואו נתחיל!",
      alert15: "כן, התנתק",
      alert16: "לא, חזרה",
      alert17: "האם להתנתק מהמערכת?",
      alert18: "צור קשר",
      contact1: "יצירת קשר",
      contact2: "נושא",
      contact3: "תוכן הפנייה",
      contact4: "שליחת פנייה",
      contactSuccessAlert: "הפניה נשלחה בהצלחה",
      demoInfo1: "מין",
      demoInfo2: "זכר",
      demoInfo3: "נקבה",
      demoInfo4: "גיל",
      demoInfo5: "מתחת ל 18",
      demoInfo6: "70 ומעלה",
      demoInfo7: "שנת עלייה",
      demoInfo8: "יליד הארץ",
      demoInfo9: "מצב משפחתי",
      demoInfo10: "נשוי/ה",
      demoInfo11: "גרוש/ה",
      demoInfo12: "אלמן/ה",
      demoInfo13: "רווק/ה",
      demoInfo14: "ארץ לידה",
      demoInfo15: "אני מעדיף לנהל משא ומתן",
      demoInfo16: "פנים אל פנים",
      demoInfo17: "בטלפון",
      demoInfo18: "בכתב: במייל או בצט'",
      demoInfo19: "לא משנה לי",
      demoInfo20: "מספר ילדים",
      demoInfo21: "עובד",
      demoInfo22: "כן",
      demoInfo23: "לא",
      demoInfo24: "המשך",
      demoInfo25: "נסיון במשא ומתן",
      demoInfo26: "רב מאוד",
      demoInfo27: "רב",
      demoInfo28: "בינוני",
      demoInfo29: "מועט",
      demoInfo30: "כלל לא",
      demoInfo31: "הגדרה דתית",
      demoInfo32: "חילוני",
      demoInfo33: "מסורתי",
      demoInfo34: "דתי",
      demoInfo35: "חרדי",
      demoInfo36: "אחר",
      demoInfo37: "תחום תעסוקה",
      demoInfo38: "שנות וותק בעבודה",
      demoInfo39: "השכלה",
      demoInfo40: "תיכונית",
      demoInfo41: "על תיכונית",
      demoInfo42: "תואר ראשון",
      demoInfo43: "תואר שני",
      demoInfo44: "תואר שלישי",
      demoInfo45: "תחום לימודים",
      demoInfo46: "התחל אבחון",
      demoInfoAgeAlert: "לא נבחרה קבוצת גיל",
      demoInfoRelAlert: "חסרה הגדרה דתית",
      demoInfoEmployAlert: "חסר תחום תעסוקה",
      demoInfoEducAlert: "לא נבחרה השכלה",
      demoInfoStart: "עברנו שלב! יאללה מתחילים באבחון",
      demoInfoTryAgain: "היתה בעיה נסה שנית",
      head1: " ביטול וחזרה לעמוד הראשי",
      head2: "האבחון שלי",
      head3: "עריכת תשובות",
      exam1: "מייצג אותי",
      exam2: "לא מייצג אותי",
      finish1: "כל הכבוד",
      finish2: "סיימת את האבחון",
      finish3:
        "רוצים לשנות את אחת התשובות? ",
      finish4:
        "לחצו על הכפתור בפינה הימנית העליונה של המסך ועדכנו את התשובה",
      finish5: "לאחר לחיצה על כפתור 'לתוצאות האבחון' ",
      finish6: "לא יהיה ניתן לערוך עוד את התשובות",
      finish7: "לתוצאות האבחון",
      info1: "כיווני התפתחות ונסיגה",
      info2: "מסייע",
      "info2-phrase": "חייבים לתת כדי לקבל",
      "info2-description": `<h1>המסייע</h1>

      <blockquote>
        <h2>חייבים לתת כדי לקבל</h2>
      </blockquote>

      <h3>
        מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
        <li>חברותי, אכפתי
          ו<strong>טוב לב</strong>
        </li>
        <li>
          <strong>אמפטי</strong>
          מכיל ובעל
          <strong>יכולת הקשבה</strong>
          הגורמת לאנשים לחפש את קרבתו ולתת בו אמון רב
        </li>
        <li>
          <strong>רגיש מאוד לצרכי האחר</strong>
          ונוטה להתמקד במילוי הרצונות והאינטרסים של אחרים.
          נותן מעצמו בנדיבות, גם מבלי שהתבקש ולעיתים קרובות מעניק לאחרים את מה שהיה רוצה לעצמו
        </li>
        <li>נענה לבקשות
          ו<strong>מתקשה לשים גבולות</strong>
          ולהגיד 'לא'
        </li>
        <li>
        משקיע מאמצים רבים במערכות היחסים בחייו, ומאמין, גם אם באופן לא מודע, כי רק כך יאהבו אותו.
        </li>
        <li>
          הוא זקוק מאוד
          <strong>להערכה ולתשומת לב</strong>
          לב וכשהוא לא מקבל אותה הוא עלול להרגיש קורבן, להגיב בכעס ולייצר מניפולציות של שתלטנות
        </li>
      </ul>

      <h3>
        התנהלות במשא ומתן
      </h3>
      <p>
        היכולת שלו להבין באופן אינטואיטיבי את הסביבה, מאפשרת לו להבין את הסיטואציה במשא ומתן על כל דקויותיה ולגלות
        <strong>רגישות ואכפתיות</strong>
        כלפי משתתפים בתהליך. יחד עם זאת הנטייה שלו להתמקד ביחסים הבינאישיים, עלולה להסיט אותו מהמטרות שלו במשא ומתן.
      </p>
      <p>
        החשש מדחייה
        וה<strong>קושי להציב גבולות</strong>,
        גורמים לו לעיתים לעשות דברים נגד רצונו.
      </p>
      <p>
        הוא נוטה לרצות את הסביבה ולכן ימצא את עצמו
        <strong>מוותר ומקריב</strong>,
        עבור הצד השני.
      </p>
      <p>
        הוא מצפה שהצד השני יזהה את הצרכים שלו גם ללא מילים, כפי שהוא עושה, וכאשר זה לא קורה ויותר מזה, אם כבר ביקש ולא נענה,
        הדבר יעורר אצלו תסכול וכעס שיביאו להתנגדות קשה לאנשים ולתהליך ויכולים לגרום לו לבצע מניפולציות של שתלטנות.
      </p>
      <h3>
        כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
        המסייע הוא
        <strong>שחקן קבוצתי</strong>.
        הוא ממוקד בעיקר ב<strong>יחסים הבינאישיים</strong>
        והם חשובים לו לא פחות מהמשימה. הוא מטפח יחסי עבודה טובים ותומך בחברי הקבוצה. יש לו יכולת אינטואיטיבית נפלאה לקרוא
        אנשים
        וסיטואציות ולהבין למה אנשים זקוקים, והוא יפעל עבורם עוד לפני שביקשו. הוא
        <strong>קשוב, מכיל ואפשר לסמוך עליו</strong>.
      </p>
      <p>
        יש לו צורך לזכות בהערכה על מעשיו ותרומתו לצוות, לעיתים אף יעדיף הוקרה על פני תמורה חומרית. יחד עם זאת, הוא מרצה
        ו<strong>מתקשה לשים גבולות</strong>.
        כל עוד הוא מצליח לשמור על האיזון שהוא זקוק לו ומקבל תשומת לב והערכה, הוא יהיה חיוני ותורם. אולם אם המאזן יתערער והוא
        ירגיש שמאמציו לא מוערכים ושהוא לא זוכה להוקרה מספקת, הוא עלול להיקלע לסערת רגשות ולתסכול רב.
      </p>
      <h3>
        שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
        <li>
          האם אני דואג גם לאינטרסים של עצמי?
        </li>
        <li>
          האם אני מעורב בחייהם של אחרים יתר על המידה?
        </li>
        <li>
          האם מאחורי הנתינה שלי יש צפייה תמידית לקבלה?
        </li>
        <li>
          האם אני תלוי בהערכה של אחרים?
        </li>
      </ul>

      <h3>
        איך תזהו התנגדות?
      </h3>
      <p>
        הצורך של המסייע להיות אהוב ולזכות בהערכה, גורם לכך שהוא יבליג וישהה ככל האפשר את תגובת ההתנגדות שלו. כשההתנגדות
        תופיע,
        היא עלולה להיראות מהצד
        כ<strong>שינוי פתאומי ביחס שלו</strong>
        וכ<strong>תגובת יתר רגשנית ודרמטית</strong>.
        לעיתים, מתוך מצוקה, הוא ינסה לבצע
        <strong>מניפולציות של שתלטנות</strong>,
        כדי לזכות בתשומת לב וביטויי הערכה שהוא כל כך זקוק להם.
      </p>
      <h3>
        איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
        ההתנהלות מול מסייע יכולה להטעות. טוב הלב והנדיבות שלו, יחד עם הנטייה שלו לוויתורים, יכולה להיתפס לעיתים כאלטרואיזם.
        אבל יש
        <strong>לשים לב שלא לחצות את הגבול</strong>
        הדק, שמעבר אליו עלול המסייע לחוש מנוצל וקורבני וינסה להחזיר אליו שליטה באמצעות תגובות דרמטיות של כעס ושתלטנות.
      </p>
      <p>
        למסייע חשוב יותר מהכל, לשמור על מערכת יחסים חיובית בעת המשא ומתן, גם אם הדבר יבוא על חשבון חלק מהמטרות שלו. היו
        מודעים לכך ושקפו לו את הערכתכם.
        <strong>הפגינו הערכה והוקרת תודה</strong>
        על מאמציו ואל תקחו אותו כמובן מאליו. שקפו לו שאתם רואים את הרצונות והצרכים שלו, במיוחד במקרים שבהם הוא מרוכז באלו
        שלכם.
        ו<strong>הפגינו אליו רגשות חיוביים</strong>.
        כאשר אתם נאלצים לסרב לבקשה שלו או להתנגד לה,
        <strong>הסבירו מדוע והציעו במידת האפשר פתרון נוסף</strong>.
        כך תזכו בשותף אמיתי, אמפטי וטוב לב למשא ומתן.
      </p>`,
      "info2-wings": `מסייע במיטבו יכול לאמץ את היכולת של
      <strong>יצירתי</strong>,
      לביטוי עצמי תוך מודעות לרגשות ולרצונות שלו, וכך לפתח את העצמי הייחודי שלו ולשחרר את הצורך באישור וקבלה תמידיים מהסביבה. אימוץ דפוסי חשיבה יצירתיים, יסייעו לו למצוא איזון בין האינטרסים שלו לאלו של האחרים במשא ומתן.
      `,
      "info2-arrows": `
      מסייע עלול ליסוג לעבר טיפוס
      <strong>הבוס</strong>
      ולהתנהג בתקיפות כלפי שותפיו למשא ומתן. במצב כזה הנתינה והעשייה שלו למען האחר יוחלפו בשתלטנות ורצון להכתיב את קצב ההתקדמות של המשא ומתן לכולם.
      `,
      info3: "ביצועיסט",
      "info3-phrase": "אני עושה, משמע אני קיים",
      "info3-description": `
      <h1>ביצועיסט</h1>

      <blockquote>
          <h2> אני עושה, משמע אני קיים </h2>
      </blockquote>

      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
          <li>
              <strong>ממוקד מטרה, יעיל ותכליתי</strong>.
              מסמן מטרה ומתקדם לעברה במהירות, עד להשגתה
          </li>
          <li>בעל
              <strong>יכולות ניהול טבעיות</strong>,
              כושר ביטוי טוב ויכולות ביצוע מרשימות
          </li>
          <li>
              <strong>תחרותי והישגי</strong>.
              מונע מהרצון להצליח ולהיות הטוב ביותר בתחומו. חדור מוטיבציה, פועל מהר, אינו חושש לעבוד תחת לחץ ופורח בסביבת
              עבודה הישגית
          </li>
          <li>
              ממוקד בעיקר
              ב<strong>זהות המקצועית</strong>.
              ומתחזק תדמית של מצליחן. מאמין שהערך שלו נקבע לפי ההישגים שלו וזקוק להערכת הסביבה ולאישורה על ההישגים שצבר
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          הביצועיסט מגדיר מטרות וחותר להשגתן. הוא ישתמש
          ב<strong>כושר הביטוי וביכולת השכנוע</strong>
          שלו,
          <strong>בהופעה המרשימה ובביטחון שהוא מקרין</strong>,
          כדי להביא את מי שמולו להסכם מהר ככל האפשר. מבחינתו, המטרה מקדשת את האמצעים.
      </p>
      <p>
          הוא מעדיף
          <strong>להתרכז במטרה</strong>,
          אינו חושף רגשות וחולשות ומצפה שגם אחרים יתנהלו כך.
      </p>
      <p>
          מעדיף לנהל את המשא ומתן
          <strong>בקצב המהיר שלו</strong>
          וכל עוד משתפים איתו פעולה, הוא מלא מוטיבציה ונכונות לתהליך. לעומת זאת, ירידה לפרטים שנראים לו משניים וקצב איטי,
          יאתגרו
          את הסבלנות שלו והוא עלול להיות מתוסכל וקצר רוח.
      </p>
      <h3>
          כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
          הוא
          <strong>קופץ ישר למשימות באופטימיות</strong>
          ומתוך אמונה שהוא יכול להשיג את המטרה.
      </p>
      <p>
          הביצועיסט
          <strong>מנהל טבעי</strong>,
          הוא ישאף לרתום את הצוות כולו להשגת המטרות ויצפה מכולם להיות חדורי מוטיבציה ומכווני מטרה. חשוב לו מאוד לזכות
          בהערכת הצוות
          ובשימור
          <strong>תדמיתו כמצליחן</strong>.
          הוא
          <strong>מעריך תוצרים</strong>
          יותר מתהליך ומאבד סבלנות כלפי מי שאיטי ממנו.
      </p>
      <p>
          הביצועיסט
          <strong>עובד טוב תחת לחץ זמן</strong>,
          רהוט ותחרותי. הוא לא נוהג להביע רגשות, או להפגין חולשה, מתוך תפיסה כי הדבר פוגע בתדמית המקצועית שלו.
      </p>
      <h3>
          שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
          <li>
              האם חשוב לי להצליח בכל מחיר?
          </li>
          <li>
              האם אני מודע לרגשות שלי ושל אחרים במשא ומתן?
          </li>
          <li>
              האם אני מתנהל מתוך עימות או שיתוף פעולה?
          </li>
          <li>
              האם אני מוכן לקבל דעות והצעות של אחרים?
          </li>

      </ul>

      <h3>
          איך תזהו התנגדות?
      </h3>
      <p>
          הביצועיסט נוטה להיות
          <strong>חסר סבלנות</strong>
          לכל מי שאינו עומד בקצב שלו. במצב כזה הוא יתקשה להקשיב, יבטל את מי שמולו ויביע את חוסר שביעות רצונו בצורה ישירה
          וגלויה,
          לעיתים אפילו בגסות רוח.
      </p>
      <h3>
          איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
          הגדירו את היעדים והמטרות
          <strong>באופן ברור</strong>.
          היו מוכנים עם
          <strong>תשובות לכל שאלה מקצועית</strong>
          שעשויה לעלות וודאו שיש בידכם את
          <strong>הידע המקצועי</strong>
          הדרוש.
      </p>
      <p>
          השתדלו
          <strong>לעמוד בקצב</strong>,
          ואף נסו להקדים את הביצועיסט בצעד אחד. השתדלו
          <strong>להימנע משיחה בנושאים אישיים ומהבעת רגשות</strong>.
      </p>
      `,
      "info3-wings": `
      ביצועיסט במיטבו יכול לאמץ את ההרגל של
      <strong>הספקן</strong>,
      להשהות מעט את קבלת ההחלטה ולבדוק היטב את האפשרויות שלפניו. הוא עשוי להצליח למתן את הנחרצות הטבעית שלו ולאזן בין עשייה אינטנסיבית להפוגה, שתאפשר לו התייחסות מעמיקה למטרותיו ותשפר את ביצועיו במשא ומתן.

      `,
      "info3-arrows": `
      נסיגה של הביצועיסט לעבר טיפוס
      <strong>משכין השלום</strong>
      עלולה להביא אותו לקיפאון מעשיה וחוסר יכולת להוציא דברים אל הפועל. הוא עלול להיות פרקטי אך לא פרודוקטיבי ולהגיב לסביבה מתוך כעס ותסכול אשר ישבשו את התנהלותו במשא ומתן.
    `,
      info4: "יצירתי",
      "info4-phrase": "החיים הם סרט ואני הכוכב הראשי",
      "info4-description": `
      <h1>יצירתי</h1>

      <blockquote>
          <h2>החיים הם סרט ואני הכוכב הראשי</h2>
      </blockquote>
      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
          <li>אמפתי מאוד לזולת,
              <strong>רגיש</strong>,
              בעל יכולת לחוות דברים בצורה משמעותית ועמוקה
          </li>
          <li>אינדיוודואליסט
              <strong>ויצירתי</strong>,
              מחפש את הדרך הייחודית שלו למימוש עצמי
          </li>
          <li>בעל טעם טוב וייחודי, הכולל לרוב אמירה אישית ומקורית, המשקפת את עולמו הפנימי העשיר
          </li>
          <li>מתפקד היטב בסביבה המאפשרת לו להביא את עצמו לידי ביטוי
          </li>
          <li>
              <strong>אמוציונלי</strong>,
              נוטה לתגובה רגשית מהירה וסוערת
          </li>
          <li>
              <strong>זקוק להערכה לעיתים קרובות</strong>.
              כאשר אינו זוכה לה, חש פגוע ועלול להגיב בדרמטיות, לעיתים קרובות ללא פרופורציה לעניין עצמו
          </li>
          <li>
              רומנטי, מתאהב בקלות
              <strong>וזקוק למענה לצרכיו הרגשיים</strong>
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          היצירתי מביא איתו למשא ומתן את נקודת המבט הייחודית שלו, מחשבה יצירתית והמון רצון טוב. הוא
          <strong>אמפטי</strong>,
          מצליח לראות את המצב ברגישות רבה
          ו<strong>ער לניואנסים</strong>.
          בדרך כלל
          <strong>יעדיף לנהל משא ומתן פנים אל פנים</strong>,
          מול אדם אחד ולא בצוות. הוא יהיה בשיאו כל עוד ירגיש שדבריו זוכים להערכה ויהיה מסופק רגשית.
      </p>
      <p>
          הוא נוטה לקחת דברים באופן אישי ולכן
          <strong>מתקשה מאוד לקבל ביקורת או התנגדות</strong>.
          מבחינתו אין ביקורת עניינית, הכל אישי. התגובה שלו לביקורת תהיה לרוב דרמטית וסוערת, אך הדרמה תמשך זמן קצר והוא
          ישוב להיות
          רגיש ואכפתי.
      </p>
      <h3>
          כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
          היצירתי מביא איתו לצוות, את
          <strong>החשיבה הייחודית והיצירתיות</strong>
          שלו ושואף לעשייה משמעותית הוא
          <strong>זקוק ליחס אישי והערכה</strong>
          על העשייה והרעיונות המיוחדים שלו.
      </p>
      <p>
          הוא רגיש ליחסים הבינאישיים בתוך הקבוצה, מעריך כנות, סולד מצביעות ועסוק בשאלת
          <strong>מקומו ומידת השתייכותו לצוות</strong>.
          כל עוד מקומו בצוות ברור לו והוא זוכה להשמיע את קולו הייחודי, הוא יפעל מתוך משמעות ורצון למצוא פתרונות יצירתיים
          ומקוריים.
      </p>
      <p>
          יחד עם זאת, כאינדיווידואליסט, היצירתי
          <strong>יעדיף לעיתים לעבוד לבד</strong>,
          כדי לא להתמודד עם מורכבות היחסים הבינאישיים.
      </p>
      <h3>
          שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
          <li>
              האם אני נותן לרגשות שלי לנהל אותי במשא ומתן?
          </li>
          <li>
              האם אני ממהר להגיב לעיתים בחוסר פרופורציה?
          </li>
          <li>
              כיצד אני יכול להביא לידי ביטוי את הייחודיות שלי ולעורר השראה?
          </li>
          <li>
              האם אני יכול למצוא הערכה עצמית בתוכי ללא תלות בזולת?
          </li>
      </ul>
      <h3>
          איך תזהו התנגדות?
      </h3>
      <p>
          קשה לטעות בהבעת ההתנגדות של היצירתי. הוא יגיב
          <strong>בסערת רגשות</strong>,
          שתהיה לעיתים קרובות חסרת שליטה.
      </p>
      <p>
          ביקורת כלפי רעיון או בקשה שלו, יתפסו לרוב כביקורת כלפי מי שהוא. לכן, הוא עלול להגיב
          ב<strong>ריחוק</strong>,
          ורצון להתנתק ממקור הביקורת,
          <strong>עד כדי אדישות</strong>
          כלפיו.
      </p>
      <h3>
          איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
          הפגינו
          <strong>עניין והערכה</strong>,
          לדבריו. החמיאו לו על עשייה חיובית, תנו לו חיזוקים ואפשרו לו
          <strong>להביע את עצמו</strong>
          ואת הרעיונות שלו.
      </p>
      <p>
          לעולם
          <strong>אל תגידו לו להירגע</strong>,
          בשעה שהוא מוצף רגשית או נמצא בסערת רגשות. תנו לסערה להירגע וליצירתי לשוב להיות האדם המעניין והיצירתי שהוא.
      </p>
      `,
      "info4-wings": `
      יצירתי במיטבו יכול לאמץ את היכולת של
      <strong>המחוקק</strong>
      לגבש משמעות פנימית ואחריות אישית ולעבור מחלומות לעשייה פרודוקטיבית. צמיחה לכיוון המחוקק, עשויה לפתח גם נאמנות לביטוי עצמי ייחודי ולתפיסת עולם שתבוא לידי ביטוי במשא ומתן.
      `,
      "info4-arrows": `
      יצירתי עלול ליסוג לעבר טיפוס
      <strong>המסייע</strong>
      ולפרש התנגדות לרעיונות היצירתיים שלו כחוסר הערכה. במצב כזה הוא ימצא את עצמו מתוסכל וכועס ויתקשה לשתף פעולה במשא ומתן.
      `,
      info5: "מדען",
      "info5-phrase": "לכל מטבע שני צדדים...לפחות",
      "info5-description": `<h1>המדען</h1>

      <blockquote>
          <h2>לכל מטבע שני צדדים... לפחות</h2>
      </blockquote>

      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
          <li>רציונלי, סקרן ואינטלקטואל, חוקר, נהנה ללמוד,
              <strong>מתמקד יותר בחשיבה ופחות בעשייה.</strong>
          </li>
          <li>
              בעל יכולת לחקור ולנתח סיטואציות באובייקטיביות ובקור רוח.
          </li>
          <li>
              חווה את העולם בעיקר
              <strong>דרך האינטלקט</strong>.
              צופה, מתבונן ומנסה להבין ופחות מעורב רגשית.
          </li>
          <li>זקוק לזמן עם עצמו
              ו<strong>שומר על פרטיותו</strong>.
          </li>
          <li>
              מעדיף לנהל קשרים חברתיים עם מספר מצומצם של אנשים, לנהל שיחות קצרות וענייניות.
          </li>
          <li>
              שומר על
              <strong>איפוק ושליטה</strong>
              ומסתפק במעט. מעריך תכונות אלה גם אצל אחרים.
          </li>
          <li>
              רגיש וביישן ומנסה להגן על עצמו מפגיעה רגשית, באמצעות
              <strong>שמירה על מרחק רגשי</strong>
              וניתוח שכלתני של הסיטואציה.
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          המדען רואה בסיטואציה של משא ומתן שדה מחקר. הוא
          <strong>יאסוף את כל המידע האפשרי</strong>,
          יבדוק את כל הנתונים
          ו<strong>לא ימהר לקבל החלטה</strong>.
          כל רעיון חדש, צריך לעבור את הבדיקה המדוקדקת שלו והוא
          <strong>זקוק לזמן</strong>
          כדי לחקור אותו לעומק בטרם יקבל החלטה.
      </p>
      <p>
          הוא
          <strong>ימעט לחשוף את רגשותיו</strong>,
          ויעשה הכל כדי לשמור על קור רוח ואובייקטיביות. הוא
          <strong>יימנע ממעורבות אישית</strong>
          וייתקשה לשאול שאלות ולהשיג מידע, שיכול להיראות כפגיעה בפרטיותו של האחר. כתוצאה מכך הוא עלול להיתפס כמרוחק או לא
          רגיש,
          בעוד שהוא ביישן ופגיע.
      </p>
      <h3>
          כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
          המדען מביא איתו לצוות המשא ומתן
          <strong>חשיבה אנליטית</strong>,
          יכולת לבדוק את המידע
          ב<strong>סבלנות</strong>
          <strong>קור רוח</strong>,
          יכולת להתעמק בפרטים ולבצע חיפוש ענייני אחר פתרונות אפשריים. הוא מעדיף לקבל החלטה רק לאחר
          <strong>שקילת כל האפשרויות</strong>,
          מה שעלול להאריך את משך הזמן של המשא ומתן ולעכב הגעה להסכם.
      </p>
      <p>
          בעבודת צוות, הוא מעדיף
          <strong>חלוקה ברורה של תפקידים ומשימות</strong>.
          הוא יתמקד במשימה שבאחריותו ויפנה אליה את מרבית המשאבים, תוך שהוא מצמצם את עצמו ואת צרכיו.
      </p>
      <p>
          הוא מעדיף להתמקד בעשייה ולא ביחסים הבינאישיים בין חברי הצוות,
          <strong>ממעט להפגין רגשות</strong>
          ומשתף מעט אנשים איתם ירגיש בטוח. לעיתים הוא יראה מרוחק או מנוכר, יש לזכור כי זו דרכו להתמודד עם הקושי הרגשי
          והחברתי שלו.
      </p>
      <h3>
          שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
          <li>
              האם אני משקיע מאמץ חשיבה רב מידי בכל עניין?
          </li>
          <li>
              האם אני נוטה להסתגר בתוך עצמי ולהתרחק מאנשים?
          </li>
          <li>
              האם קשה לי לקבל החלטה בלחץ זמן?
          </li>
          <li>
              האם אני מעדיף לבדוק את הנתונים לבד ולקבל החלטות לבד?
          </li>
      </ul>
      <h3>
          איך תזהו התנגדות?
      </h3>
      <p>
          התנגדות עלולה להיווצר כאשר
          <strong>מטילים ספק</strong>
          במסקנות המבוססות על מידע וניתוח שלו, כאשר אחרים אומרים משהו, או מקבלים החלטה שנראית לו
          <strong>לא מבוססת או חסרת הגיון</strong>
          ובעיקר כאשר צריך לקבל החלטות
          ב<strong>לחץ של זמן</strong>.
          בנוסף, גם
          <strong>חוסר בהירות לגבי תחומי אחריות</strong>
          בצוות, עלולה להביא להתנגדות.
      </p>
      <p>
          במצב של התנגדות, המדען עלול לקחת צעד אחורה,
          <strong>להתרחק ולהסתגר בתוך עצמו</strong>.
          הוא
          <strong>ינתק מגע</strong>,
          כדי לא להיות מוצף רגשית ולעיתים יהיה קשה להבין את הסיבות להתנהגות שלו ולזהות שהן תגובה להתנגדות. התגובות שלו
          עלולות
          להראות כהתרחקות
          וכ<strong>התבצרות</strong>
          נוספת בעמדותיו ולעורר כעס בצד השני.
      </p>
      <h3>

          איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
          דברו אליו במספרים
          <strong>והציגו לו את הדרך לנתונים שהשגתם</strong>.
          נסו להבין את האופן שבו הוא מסתכל על הדברים. אל תשאלו שאלות אישות ואל תעמיסו עליו מידע אישי, או רגשי.
          אפשרו לו
          <strong>מספיק זמן</strong>
          לבדיקת הנתונים ולניתוח שלהם. כאשר הדבר אפשרי, אפשרו לו
          <strong>מרחב פעולה עצמאי</strong>.
          הבינו שמה שנראה לעיתים כהסתגרות וחוסר שיתוף פעולה, נועד לחפות על רגישות ופגיעות רבה.
      </p>
      `,
      "info5-wings": `
      מדען במיטבו יכול לאמץ את היכולת של
      <strong>הבוס</strong>,
       להיות בעל חזון ולהוביל חשיבה
      חלוצית. הוא
      ימנף את הלמידה והחקירה
      שהוא מבצע היטב
      לביצועים חדשים ולקידום המשא ומתן.
      `,
      "info5-arrows": `
      מדען עלול ליסוג לעבר טיפוס
      <strong>ההרפתקן</strong>,
      במצב כזה הוא ימצא את עצמו מדלג ממחשבה אחת לאחרת, ללא יכולת להתמקד
      ולהתקדם לעבר קבלת
      החלטה. יהיה קל מאוד להסיט אותו לעניינים שוליים במהלך המשא ומתן והוא יחוש חוסר יציבות משתק.
      `,
      info6: "ספקן",
      "info6-phrase": "אם משהו יכול להשתבש, הוא בטוח ישתבש",
      "info6-description": `<h1>ספקן</h1>
      <blockquote>
          <h2> אם משהו יכול להשתבש, הוא בטוח ישתבש</h2>
      </blockquote>
      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
          <li>
              אחראי,
              <strong>מסור ומחויב</strong>,
              יציב ונאמן לאנשים ולמטרות
          </li>
          <li>הוא
              <strong>תופס את העולם כמקום לא בטוח</strong>,
              כזה שלא ניתן לסמוך עליו ולכן מייצר לעצמו רשת ביטחון ונערך תמיד לתרחיש הגרוע ביותר
          </li>
          <li>
              מטיל ספק בכל דבר, בעיקר בעצמו. חושש מכישלון ומעדיף את המוכר
          </li>
          <li>
              <strong>מחושב וזהיר</strong>,
              מעדיף ללכת על בטוח וממעט לקחת סיכונים
          </li>
          <li>
              בוחן היטב את כל הסיכונים האפשריים
              ו<strong>מתקשה לקבל החלטות</strong>
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          הספקן מביא איתו למשא ומתן את הספקות הגדולים שלו ואת הצורך
          <strong>לבדוק כל דבר ולחפש כוונות נסתרות</strong>
          במה שנאמר ובמה שמפורש. קשה לו לסמוך על אחרים ויותר מזה, קשה לו לסמוך על עצמו והוא
          <strong>מתכונן תמיד לתרחיש הגרוע ביותר</strong>.
          יחד עם זאת הוא
          <strong>הגון ואחראי ואפשר לסמוך עליו</strong>
          שיעמוד במה שסוכם איתו.
      </p>
      <p>
          הצורך שלו
          <strong>לבדוק היטב את כל החלופות והתרחישים האפשריים</strong>,
          עלול להאריך את קבלת ההחלטות במשא ומתן ולגרום לצד השני ספקות ביחס לכוונותיו.
      </p>
      <h3>
          כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
          הספקן
          <strong>חש ביטחון</strong>,
          כאשר הוא חלק מצוות. הוא ניחן בכישורים חברתיים טבעיים, המסייעים לו בעבודה המשותפת. הוא
          <strong>מגלה אחריות ומחויבות</strong>
          להצלחת הצוות ומוביל קול שקול ואחראי.
      </p>
      <p>
          יחד עם זאת, הספקן נוטה לזהירות יתר
          ו<strong>מתקשה לקבל החלטות</strong>.
          ההססנות שלו והקושי לקחת אחריות ולהוביל מהלך, יחד עם התמקדות היתר בקשיים ולא בהזדמנויות, עלולה לייצר דינמיקה
          שלילית בצוות
          שירצה להתקדם במשא ומתן. לעומת זאת, כאשר הוא זוכה
          <strong>להערכה ולחיבה</strong>
          בתוך הצוות, הוא יחוש ביטחון ויוכל לתרום תובנות לעבודת הצוות.
      </p>
      <h3>
          שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
          <li>
              האם לתסריט הגרוע שבראשי, יש אחיזה במציאות?
          </li>
          <li>
              האם הדאגה שלי מוגזמת?
          </li>
          <li>
              האם אני יכול למצוא דרכים שיעזרו לי להפחית את רמות החרדה מהמשא ומתן ומתוצאותיו?
          </li>
          <li>
              האם פירוק החלטה גדולה להחלטות קטנות יכול לסייע לי להתקדם בתהליך?

      </ul>
      <h3>
          איך תזהו התנגדות?
      </h3>
      <p>
          הספקן יגלה התנגדות
          <strong>כאשר המצב לא ברור לו</strong>.
          אי וודאות, עמימות ותחושה שמי שמולו מתנהל בחוסר זהירות או מקל ראש בספקות שהוא מעלה, יגרמו להתנגדות. כך גם, דרישה
          לקבל
          החלטה במה שבעיניו נתפס כזמן לא מספיק לבחינת החלופות.
      </p>
      <p>
          במצב של התנגדות, הוא
          <strong>נוטה להתרחק ולהסתגר</strong>
          ומביע פסימיות וביקורת.
      </p>
      <h3>

          איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
          לעולם אל תגידו לספקן "יהיה בסדר", אלא
          <strong>הראו לו איך יהיה בסדר</strong>.
          תנו לו את
          <strong>כל המידע</strong>
          שיש לכם,
          <strong>היו ישירים וגלויים</strong>,
          וצרו מרחב של ביטחון וודאות, שבתוכו יוכל לפעול.
      </p>
      <p>
          <strong>גלו הערכה</strong>
          לאחריות ולנאמנות שלו.
          <strong>עודדו</strong>
          אותו לקבל החלטות ולהתייחס גם להזדמנויות, אך
          <strong>אל תשפטו</strong>
          את הלחץ או החרדות שלו.
      </p>
      `,
      "info6-wings": `
      ספקן במיטבו יכול לאמץ את היכולת של
      <strong>משכין השלום</strong>,
      להקשיב לצדדים במשא ומתן ולשתף פעולה מתוך הנאמנות והאחריות הטבעית שלו, אשר יעזרו לו לפתח בהדרגה אמון באחר ולהוביל תהליכים של משא ומתן.
      `,
      "info6-arrows": `
      ספקן עלול ליסוג לעבר טיפוס
      <strong>הביצועיסט</strong>
      ולהגיב באופן לא רציונלי, להאשים אחרים בחוסר התקדמות במשא ומתן ולהיות מאוד לוחמני.
      `,
      info7: "הרפתקן",
      "info7-phrase": "חיים רק פעם אחת",
      "info7-description": `<h1>הרפתקן</h1>
      <blockquote>
          <h2> חיים רק פעם אחת</h2>
      </blockquote>

      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
          <li>
              חברותי, אופטימי, קליל, כריזמטי וספונטני
          </li>
          <li>ההרפתקן
              <strong>זקוק לחופש ולמרחב</strong>,
              הוא נמצא בתנועה מתמדת
              ו<strong>מתקשה להתמקד בדבר אחד</strong>
          </li>
          <li>
              השקפת העולם של ההרפתקן, מניעה אותו לחפש ריגושים והנאות ולהימנע מסבל ומכאב
          </li>
          <li>
              התנועה שלו קדימה היא פיזית ומנטלית. חופש התנועה כולל עבורו גם את היכולת לנוע מרעיון אחד לאחר ולממש את
              <strong>שפע הרעיונות</strong>
              שלו
          </li>
          <li>
              הוא מתחבר בקלות לאחרים,
              <strong>בעל יכולת ביטוי וכושר שכנוע</strong>,
              מביט באופטימיות על האפשרויות וסוחף אחרים בהתלהבות שלו
          </li>
          <li>
              יחד עם זאת, הוא עלול להיות חסר סבלנות, מצפה לתוצאות מידיות וחסר משמעת פנימית
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          ההרפתקן נכנס למשא ומתן אופטימי ונלהב. הוא
          <strong>שופע רעיונות</strong>
          ו<strong>קופץ מדבר לדבר</strong>,
          בלי לבדוק את הדברים עד הסוף. הוא לא נבהל מקשיים, רואה תמיד את חצי הכוס המלאה
          ו<strong>מתייחס למשבר כאל הזדמנות</strong>.
      </p>
      <p>הרצון שלו להתקדם ולסיים את המשא ומתן כדי לנוע לדבר הבא, עלול להביא אותו לקבל החלטות, מבלי לבדוק את כל הפרטים או
          תוך כדי
          לקיחת סיכונים.
      </p>
      <h3>
          כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
          ההרפתקן יהיה פעמים רבות הרוח החיה בצוות. הוא
          <strong>יוצר אווירה קלילה</strong>
          ומדביק אחרים בהתלהבות שלו. יש לו יכולת לעבור מנושא לנושא ולשלב בין תחומים. הוא מביא איתו לצוות
          <strong>חדשנות ומחשבה מקורית</strong>
          ואינו חושש ליישם אותם ולשכנע אחרים להצטרף אליו. אין לו עניין בהיררכיה ובסמכות והוא
          <strong>מסתכל על כולם כשווים</strong>.
          קל לו לפעול כחלק מצוות, כל עוד החופש שלו נשמר.
      </p>
      <p>
          ההרפתקן
          <strong>מביע את דעתו בגלוי ובנחרצות</strong>,
          לעיתים בחוסר סבלנות ותוך ביטול דעות אחרות. הוא מאמין שנקודת המבט שלו היא הנכונה ביותר
          ו<strong>מתקשה לקבל דעות נוספות</strong>.
          הוא מתקשה לקחת אחריות על כישלון. נוטה להאשים אחרים, לעשות רציונליזציה לארוע או להקטין את חשיבותו.
      </p>
      <h3>
          שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
          <li>
              האם חוסר ההתמקדות שלי מונע ממני לסיים משימות?
          </li>
          <li>
              האם אני נוטה לקחת סיכונים שלא לצורך?
          </li>
          <li>
              האם הערכה שלי מבוססת על הנתונים האובייקטיביים?
          </li>
          <li>
              האם אני נוטה לקפוץ מרעיון לרעיון, בלי לבדוק דברים עד הסוף?
          </li>

      </ul>
      <h3>
          איך תזהו התנגדות?
      </h3>
      <p>
          ההרפתקן יגלה התנגדות כאשר מגבילים את
          <strong>החופש שלו</strong>,
          הפיזי והמנטלי. כשדורשים ממנו להתחייב
          <strong> להשלים משימה או לעמוד בלוחות זמנים נוקשים</strong>.
          כשהוא בהתנגדות הוא עשוי להיות אימפולסיבי.
      </p>
      <p>
          הוא יאבד סבלנות, יאשים אחרים בתוצאות ויבנה לעצמו תמונת מציאות, שבה הארוע חסר חשיבות או שהטעות אצל אחרים ובכל
          מקרה הוא
          ימצא הסברים רציונליים לכישלון.
      </p>
      <h3>
          איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
          ההתנהלות מול ההרפתקן דורשת
          <strong>עמידה בקצב המהיר שלו</strong>,
          והבנה, כי כל עוד הוא נהנה הוא יתמיד במשא ומתן. הוא מתמקד בהנאה ומתקשה לשמוע צרות של אחרים ולכן מוטב לא להעמיס
          עלו. כדי
          להביא אותו לשיתוף פעולה כדאי להשתמש ברוח חיובית
          ו<strong>לדבר על התועלות וההנאות</strong>
          ששני הצדדים ירוויחו מכך.
      </p>
      <p>
          חשוב לתת לו אפשרות להעלות
          <strong>רעיונות ופתרונות יצירתיים</strong>,
          אך לא לצפות ממנו להיכנס לפרטים הקטנים הכרוכים במימוש הרעיון. ההרפתקן הוא
          <strong>יזם</strong>,
          אך לא מבצע. צריך לאפשר לו את
          <strong>מרחב הפעולה והגמישות</strong>,
          להם הוא זקוק.
      </p>
      `,
      "info7-wings": `
      הרפתקן במיטבו יכול לאמץ את היכולת של
      <strong>המדען</strong>,
      לחקור ולבדוק את הדברים לעומק ולאסוף את המידע הנדרש לפני קבלת החלטה. הוא יוכל לאזן את הלהט שלו לפעול ולהגיב באימפולסיביות ויצליח לנהל את המשא ומתן עם פחות הסחות דעת.
      `,
      "info7-arrows": `
      הרפתקן עלול ליסוג לעבר טיפוס
      <strong>המחוקק</strong>
      ולמצוא את עצמו פועל בנוקשות, מבלי יכולת לשמוע דעות נוספות. הוא עלול לאבד את הגמישות והאופטימיות שלו ולהתנהל במשא ומתן בצורה שאינה מכוונת מטרה, לעיתים תחת מעטה של עקרונות וערכים.
      `,
      info8: "בוס",
      "info8-phrase": "מי שלא איתי - נגדי",
      "info8-description": `<h1>בוס</h1>
      <blockquote>
          <h2> מי שלא איתי נגדי </h2>
      </blockquote>
      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
          <li>הבוס הוא
              <strong>מנהיג מלידה</strong>,
              ישיר, כריזמטי ובעל נוכחות עוצמתית
          </li>
          <li>לפי השקפת עולמו, יש שתי אפשרויות בלבד: להיות חזק או חלש, מנצח או מנוצח. לכן הוא מקפיד להפגין
              <strong>שליטה וחוזק</strong>
              ונרתע מגילויים של פגיעות וחולשה
          </li>
          <li>הוא מביע את דעתו באופן גלוי ובנחרצות, בעל ביטחון עצמי, לא נרתע מקשיים ולא חושש מעימותים</li>
          <li>רואה בצדק ערך חשוב ומוכן להילחם ולהגן על חלשים ומנוצלים</li>
          <li>מעריך יושרה וכבוד,
              <strong>נמנע ממחוות רגשיות</strong>
              וסולד מצביעות והעמדת פנים
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          הבוס נכנס למשא ומתן במלוא העוצמה. הוא
          <strong>לא חושש מעימותים</strong>
          ומוכן להילחם על דעתו, גם אם מי שמולו בסמכות גבוהה יותר ממנו. הוא
          <strong>יביע את דעתו באופן ישיר</strong>
          וישתף פעולה עם מי שמסכים איתו. מי שלא, ימצא את עצמו מהר מאוד בעימות גלוי וחזיתי מולו.
      </p>
      <p>
          חשוב לו שהמשא ומתן יתנהל
          <strong>ביושרה</strong>,
          מגיב בכעס לצביעות והעמדת פנים. מעריך מאוד גישה ישירה
          ו<strong>חסר סבלנות כלפי גילויי חולשה ורגשנות</strong>
          אצל אחרים.
      </p>
      <p>
          הבוס מחויב לתהליך ועושה כל שיכול כדי לקדם אותו. יחד עם זאת, הוא
          <strong>מצפה לתוצאות מידיות</strong>,
          ויהיה לעיתים חסר סבלנות לתהליכים ארוכים ומורכבים.
      </p>
      <h3>
          כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
          הבוס הוא המנוע של הצוות, הוא
          <strong>דוחף, מעודד ומניע לפעולה</strong>.
          הוא מנהיג טבעי, שדבר לא עומד בפניו. הוא ילחם לטובת כל מי שהוא תופס כבן ברית ומנגד, לא יהסס לפגוע במי שלא יעמוד
          לצידו.
      </p>
      <p>
          הוא יביע את דעתו בנחרצות ויתקשה לשמוע דעות אחרות. יחסים בינאישיים אינם מטרה בעיניו, הוא
          <strong>מתקשה בהבעת רגשות ופגיעות</strong>
          ומתרכז בעשייה עצמה.
          <strong>אינו חושש מעימותים</strong>
          בתוך הצוות, כל עוד הם מקדמים את המטרה ובעיקר, כל עוד ידו על העליונה.
      </p>
      <p>
          הבוס
          <strong>מצפה לקבל את כל המידע הדרוש</strong>,
          כדי לקדם את המשא ומתן. הוא דורש מחברי הצוות, לפעול על פי אמות המידה שלו ולהתנהל בפתיחות וביושרה. כאשר זה לא
          קורה, הוא
          יגיב בזעם ולעיתים בנקמנות.
      </p>
      <h3>

          שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
          <li>
              האם אני נכנס לעימותים מיותרים?
          </li>
          <li>
              האם אני מאפשר לאחרים להביע את דעתם?
          </li>
          <li>
              האם הגישה שלי מונעת מאחרים לתת לי מידע נחוץ עבורי?
          </li>
          <li>
              האם יש דרכים נוספות, פרט לדרך שלי, כדי לקדם את המשא ומתן?
          </li>
      </ul>
      <h3>
          איך תזהו התנגדות?
      </h3>
      <p>
          הבוס יביע התנגדות כאשר הוא מגלה
          <strong>צביעות או העמדת פנים</strong>,
          כאשר אנשים
          <strong>לא לוקחים אחריות</strong>
          על מעשיהם וכאשר יש
          <strong>פגיעה בחלשים</strong>.
      </p>
      <p>
          ההתנגדות של הבוס היא
          <strong>גלויה ועוצמתית</strong>.
          הכעס שלו שורף וחורך כל מה שבסביבה. הוא יביע התנגדות
          <strong>באופן ישיר</strong>,
          ב<strong>כעס גדול</strong>
          ולעיתים
          <strong>בזלזול</strong>.
      </p>
      <h3>
          איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
          הבוס דורש מאחרים את מה שהוא דורש מעצמו. נהגו בו
          <strong>בכבוד</strong>,
          שימו את הקלפים על השולחן ודברו בצורה
          <strong>ישירה וגלויה</strong>.
          התנהלו
          ב<strong>יושרה</strong>
          והמנעו מרגשנות ומחשיפה רגשית.
      </p>
      <p>
          כאשר הוא כועס,
          <strong>התרחקו מעט, אך אל תעלמו</strong>.
          אפשרו לו להירגע ושובו לדבר איתו בענייניות. גילויי פחד או כעס, רק ילבו את האש.
      </p>
      <p>
          היו מודעים לרצונו, לסגור הסכם ולהגיע לתוצאות מהר. כאשר זו מטרה משותפת, צרו תנאים שיאפשרו זאת. זכרו שהוא
          <strong>מעריך תוצאות ושותפים המתנהלים בסטנדרטים ובקצב שלו</strong>.
      </p>

      `,
      "info8-wings": `
      בוס במיטבו יכול לאמץ את היכולת של
      <strong>המסייע</strong>,
      להנהיג באופן טבעי, מתוך הקשבה, נדיבות ודאגה לאחרים. הוא יוכל למתן את הנטייה שלו לפעול לבד וייקח אחריות, יעודד את שותפיו ויעזור להם לעבור משברים ולהתקדם מקונפליקטים למשא ומתן בהסכמה.
      `,
      "info8-arrows": `
      בוס עלול ליסוג לעבר טיפוס
      <strong>המדען</strong>,
      במצב כזה הוא ירצה לשלוט בסביבה שלו באמצעות איסוף מידע אובססיבי. הוא יטה שלא לשתף אחרים במידע ויתנהל במשא ומתן עם קלפים צמודים לחזה. הוא יצור יריבויות ועלול אף להשתמש באיומים ובנקמה.
      `,
      info9: "משכין שלום",
      "info9-phrase": "יושב על הגדר...בסדר עם כולם",
      "info9-description": `<h1>משכין שלום</h1>
      <blockquote>
          <h2> יושב על הגדר... בסדר עם כולם </h2>
      </blockquote>
      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class="type-description__list">
          <li>טוב לב, רגיש,
              <strong>מכיל ומשתלב</strong>
              בהרמוניה בסביבתו
          </li>
          <li>השקפת העולם של משכין השלום מנחה אותו
              <strong>להימנע מעימותים</strong>,
              לשמור על
              <strong>שלווה</strong>
              ולהשרות
              <strong>אווירה נעימה</strong>
          </li>
          <li>הוא נוטה לשתף פעולה עם אחרים,
              <strong>סבלני וקשוב</strong>,
              אך לא מביע בגלוי את האינטרסים שלו ופעמים רבות מדחיק את רצונותיו
          </li>
          <li>
              נוטה לצבוע את המציאות באור חיובי, לעיתים תוך התעלמות מבעיות. מעדיף להסיח את דעתו, באמצעות התעסקות בדברים
              אחרים ולדחות
              את העיסוק במה שמצריך את מעורבותו
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          משא ומתן הוא סיטואציה לא פשוטה ולא טבעית עבור משכין השלום. עצם הדרישה לנקוט עמדה ולהביע את דעתו, מאתגרת אותו.
          הוא
          <strong>מוכן לקבל דעות שונות</strong>
          משלו ויהיה סבלני ונעים, ובלבד שלא ידרש לצאת מהאיזון הפנימי שלו ולהתעמת מול הצד השני. הוא יעדיף לשמוע את הדרישות
          של הצד
          השני
          ו<strong>להתפשר, גם במחיר של ויתור על האינטרסים שלו</strong>.
      </p>
      <p>
          <strong>הצורך להגיע להחלטה</strong>,
          במיוחד
          ב<strong>לחץ זמן</strong>,
          קשה לו ומעורר אצלו התנגדות. פעמים רבות הוא נקלע למשא ומתן, מבלי שהגדיר לעצמו מהם האינטרסים שלו ומהן המטרות.
      </p>
      <h3>
          כאשר הוא חלק מצוות המשא ומתן
      </h3>
      <p>
          משכין השלום מביא איתו לצוות, את נקודת המבט של
          ה<strong>מגשר</strong>.
          הוא יכול לראות את העמדות של שני הצדדים למשא ומתן ולהבין אותן, כל עוד אינו נדרש לנקוט עמדה. יש לו יכולת לצמצם
          מחלוקות
          בתוך הצוות
          ו<strong>לגשר על פערים בעמדות</strong>,
          עד כדי
          <strong>יצירת מכנה משותף ומלכד</strong>
          בין חברי הצוות להשגת המטרה. הוא
          <strong>נהנה מעבודת צוות</strong>,
          כל עוד הדברים בשליטה.
      </p>
      <p>
          הוא משתף פעולה בדרך נעימה. הוא לא תחרותי ולכן קל לו להיות חלק מקבוצה. יחד עם זאת, הוא לא האדם שיידחף את הצוות
          להגיע
          למטרה. במיוחד אם התוצאה כרוכה בשינויים שיוציאו אותו מאזור הנוחות שלו. הוא נמנע בדרך כלל מהבעת דעתו
          ו<strong>מתקשה לנקוט עמדה ברורה</strong>.
          הוא
          <strong>זקוק לזמן</strong>
          כדי ללמוד את הקונפליקט מכל היבטיו
          ו<strong>נוטה להתעלם ממה שמפריע לו</strong>.
          לכן הוא נתפס כנחמד ונוח, אך גם כפסיבי ולעיתים מובן מאליו.
      </p>
      <h3>
          שאלות שכדאי לשאול לפני ובמהלך משא ומתן
      </h3>
      <ul class="type-description__list">
          <li>
              האם אני נוטה להתעלם מבעיות?
          </li>
          <li>
              האם אני משמיע את דעתי במשא ומתן, או רק מקשיב לאחרים?
          </li>
          <li>
              האם אני נתפס כמובן מאליו?
          </li>
          <li>
              האם אני נוטה לדחיינות והמשימות נערמות על שולחני?
          </li>
      </ul>
      <h3>
          איך תזהו התנגדות?
      </h3>
      <p>
          כאשר משכין השלום
          <strong>נדרש לקבל החלטה, בעיקר בלחץ זמן, להתעמת ולומר את דעתו</strong> -
          הדבר גורם לו לחוסר נוחות ולהתנגדות.
      </p>
      <p>
          הביטוי הראשון והשכיח להתנגדות יהיה
          <strong>הסתגרות</strong>.
          הוא ידחיק את הכעס וינסה לשמור על איזון למראית עין. ככל שההתנגדות הפנימית שלו תתעצם, במיוחד אם ילחצו אותו לפינה
          או ידברו
          אליו בגסות רוח, הוא עלול להיות פסיבי ואדיש, או אגרסיבי וחסר סבלנות.
      </p>
      <h3>
          איך להתנהל איתו במשא ומתן?
      </h3>
      <p>
          משכין השלום מעדיף להישאר בתוך
          ה<strong>שגרה המוגנת</strong>
          שלו, כל דרישה לצאת ממנה מערערת את
          <strong>האיזון הפנימי</strong>
          שלו. קבעו לוחות זמנים משותפים, אך
          <strong>הימנעו מלהלחיץ אותו, או להביע ביקורת</strong>
          על כך שאינו עומד בלוחות הזמנים.
      </p>
      <p>
          היו קשובים אליו והעריכו את היכולת שלו להכיל, אך אל תנצלו אותה.
          <strong>דברו איתו בנועם</strong>
          ונסו להגיע לתוצאה, שתביא לידי ביטוי את האינטרסים של שני הצדדים. הובילו את המשא ומתן
          ל<strong>הסכמה</strong>.
          משכין השלום אינו מוביל, אך הוא
          <strong>מעריך מאוד שיתוף פעולה</strong>.</p>
          `,
      "info9-wings": `
      משכין שלום במיטבו יכול לאמץ את הדפוסים של
      <strong>הביצועיסט</strong>
      ולהתמודד טוב יותר עם החשש מקונפליקטים, לייצר הרמוניה בין יחידים וקבוצות במשא ומתן ולקרב אותם לקראת הסכמות ושיתופי פעולה.
      `,
      "info9-arrows": `
      משכין שלום עלול ליסוג לעבר טיפוס
      <strong>הספקן</strong>
      ולמצוא את עצמו נמנע לחלוטין מהבעת עמדה והתמודדות עם קונפליקטים במשא ומתן, עד כדי הדחקת הרצונות שלו, ניתוק ואפילו חוסר תפקוד.
      `,
      info10: "מחוקק",
      "info10-phrase": "יש רק דרך אחת נכונה - הדרך שלי",
      "info10-descripton": `<h1>מחוקק</h1>
      <blockquote><h2>יש רק דרך אחת נכונה - הדרך שלי</h2></blockquote>
      <h3>
          מאפיינים עיקריים
      </h3>
      <ul class='type-description__list'>
          <li>אחראי, אידיאליסט, אמין ומסור</li>
          <li>בעל
              <strong>משמעת עצמית גבוהה</strong>
              ושאיפה לחיות את החיים בדרך שהוא מכתיב
          </li>
          <li>הפרפקציוניזם של המחוקק גורם לו לבצע כל משימה, ברצינות, בנאמנות
              <strong>בסטנדרטים גבוהים</strong>
              שהוא מציב לעצמו וללא פשרות
          </li>
          <li>חרוץ ושקדן ובעל
              <strong>
                  יכולת טבעית לנהל משימות ולהשלים אותן
              </strong>
          </li>
          <li>שותף
              <strong>מסור ונאמן</strong>,
              אפשר לסמוך עליו והוא מוכן להקריב מעצמו למען המשימה ולמען שותפיו
          </li>
          <li>
              <strong>מבקר ומשפר את עצמו כל הזמן</strong>
              מתוך אמונה פנימית שתמיד יש מה לשפר והוא
              <strong>דורש
                  גם מהסביבה שלו להשתפר ולעמוד בסטנדרטים שלו
              </strong>
          </li>
      </ul>
      <h3>
          התנהלות במשא ומתן
      </h3>
      <p>
          מגיע למשא ומתן עם
          <strong>מטרה ברורה</strong>,
          תוכנית פעולה מסודרת ותפיסה מגובשת, לגבי הדרך שבה הדברים צריכים להתנהל. כל עוד
          התהליך מתנהל על פי העקרונות שלו, הוא ישתף פעולה. כאשר מי שמולו במשא ומתן, פועל משיקולים שאינם תואמים את
          <strong>הסטנדרטים הערכיים</strong>
          ואת
          תפיסת
          העולם שלו, תתעורר התנגדות והוא
          <strong>יתקשה להתפשר</strong>.
      </p>
      <p>
          כפרפקציוניסט הוא זקוק
          <strong>למידע ברור ומסודר</strong>,
          הכולל את כל הפרטים הרלוונטיים. חשוב לו לדעת מהו סדר היום, על מה דנים, מה האינטרסים של הצדדים ומה צפוי לקרות בכל
          שלב בתהליך. הוא זקוק לזמן כדי להתכונן ולכן מעריך
          <strong>תכנון מוקדם ועמידה בלוחות הזמנים</strong>.
      </p>
      <p>
          במהלך המשא ומתן, הוא נוטה להיצמד
          <strong>לדפוסים ולאמונות</strong>
          שהוא מכיר ויתקשה להתגמש ולקבל רעיונות ופתרונות חדשים. במקרה של שינויים, הוא
          <strong>זקוק לזמן</strong>
          כדי לעכל את הסיטואציה החדשה.
          <strong>אם ירגיש שהוא מאבד שליטה</strong>
          והדברים לא מתנהלים כפי שתכנן, הוא יגלה התנגדות רבה, שתבוא לידי ביטוי
          בביקורתיות ובשיפוטיות כלפי אחרים. הוא ייטה להתמקד
          במה שלקוי ויתקשה לראות את מה שניתן להפיק מהתהליך. לכן, עלול להיתפס כנוקשה, ולעיתים אפילו כטרחן.
      </p>
      <h3>כאשר הוא חלק מצוות המשא ומתן </h3>
      <p>
          תפיסת העולם של המחוקק מביאה אותו פעמים רבות לנהל, גם אם לא רשמית, את המשא ומתן. הוא אחראי ורציני, בעל
          <strong>כושר ארגון</strong>
          והוא יקח על עצמו משימות רבות ולא יחסוך מעצמו עבודה ואחריות. היכולת שלו
          <strong>לשלוט בפרטים</strong>
          מקשה עליו לעיתים להאציל סמכויות לאחרים.
      </p>
      <p>
          עם זאת, הוא דורש ממי שנמצא איתו לעמוד באותם סטנדרטים. הוא
          <strong>יחתור לשלמות</strong>
          ויבדוק כל הזמן איך ניתן
          <strong>לשפר את התהליך</strong>
          ולהגיע לתוצאות טובות יותר. לעיתים הוא מתנהל בתחושה שיש לו שליטה בכל הפרטים ולכן כאשר הדברים לא מתנהלים כמו
          שהוא רוצה, או
          כשנסיבות חיצוניות מפריעות לתהליך הוא יחוש כעס וטינה ויהיה מלא ביקורת.
      </p>
      <p>
          הדרך המאוד ברורה של המחוקק לעצמו לא תמיד מובנית למי שאיתו. מהצד זה יכול להראות לעיתים, כמו התעקשות מיותרת
          וטרחנית על
          הפרטים, במקום להתקדם עם מה שיש.
      </p>
      <p>
          הפרפקציוניזם של המחוקק, עלול לגרום
          <strong>לדחיינות ולקושי לעמוד בלוחות זמנים</strong>.
          המחוקק זקוק לעיתים לזמן כדי לעשות את הדברים בדרך שלו, מה שעלול לעורר כעס כשיש דד ליין לסיום התהליך או לחץ לסגור
          עסקה
          וכאשר הצוות רוצה להתקדם.
      </p>
          <h3>
              שאלות שכדאי לשאול לפני ובמהלך משא ומתן </h3>
          <ul>
              <li>
                  האם הדרך שלי היא הדרך היחידה?
              </li>
              <li>
                  האם יש דרכים נוספות שאני יכול לקבל, מבלי לוותר על היושרה שלי?
              </li>
              <li>
                  האם אני מעדיף להיות צודק או יעיל? </li>
              <li>
                  האם יש מישהו בצוות שאני יכול להאציל אליו סמכויות? </li>
              <li>
                  האם יש דרך להסתכל על הסיטואציה בהומור? להקליל אותה? </li>
          </ul>

          <h3>
              איך תזהו התנגדות?
          </h3>
          <p>
              המחוקק תופס כעס כרגש לא רצוי, לכן הוא נוטה להדחיק אותו.
              <br>
              ההתנגדות שלו תבוא לידי ביטוי
              <strong>באי שקט, חוסר סובלנות, תסכול וביקורת שיפוטית</strong>
              במצב כזה, הוא
              <strong>יראה ממש על הקצה</strong>
              וכל דבר קטן יכול לגרום לו להתפוצץ ואז לכעוס על עצמו ולהתמלא ברגשי אשמה.
              <br>
              ההתנגדות שלו, תתבטא בעיקר בביקורתיות ושיפוטיות כלפי אחרים והתמקדות במה שלקוי, במקום במה שאפשר להפיק מהתהליך.
          </p>
          <h3>
              איך להתנהל איתו במשא ומתן?
          </h3>
          <p>
              חשוב לו לעמוד במטרות שהציב לעצמו. לכן
              <strong>גלו עניין</strong>
              אמיתי במטרות ובאינטרסים שלו ונסו למצוא יחד איתו
              <strong>דרכים יצירתיות להרחבת האינטרסים המשותפים</strong>.
              כאשר אתם מגישים לו הצעה, דאגו שתהיה
              <strong>מפורטת ומדויקת</strong>
              ככל האפשר. השליטה בפרטים מקלה עליו ומאפשרת לו לוודא שהוא מתקדם בהתאם למטרות ולדרך שלו.
          </p>
          <p>
              המחוקק בודק ומבקר את עצמו כל הזמן, כיוון שרואה בביקורת עצמית דרך להשתפר ולעמוד בסטנדרטים הגבוהים שהציב לעצמו,
              יחד עם זאת,
              <strong>הוא רגיש מאוד לביקורת של אחרים כלפיו</strong>.
              כאשר הוא מבקר אתכם, נסו לקבל את הביקורת שלו כחלק מהתהליך שהוא חווה ואל תמהרו לקחת את הדברים באופן אישי. התעלו על
              תחושת הכעס הטבעית ונסו להתעניין ולהבין מה באמת מפריע לו וכיצד ניתן לפתור את זה.</p>
              `,
      "info10-wings": `מחוקק במיטבו יכול לאמץ את היכולת של <strong>ההרפתקן</strong>, לבחון אפשרויות והזדמנויות חדשות, לגלות גמישות ולהרפות מהנוקשות והקיבעון האופייניים לו. הוא יוכל להוביל תהליכי משא ומתן, מתוך הקשבה נטולת שיפוטיות וביקורת עצמית שתשתלב עם השאיפה שלו למצוינות.
        `,
      "info10-arrows": `מחוקק עלול ליסוג לעבר הטיפוס <strong>היצירתי</strong> במצב זה הדעתנות שלו עלולה להפוך לשיפוטיות וביקורת חסרת סבלנות כלפי אחרים והוא יפגין כעס ונוקשות ויתקשה למצוא שותפים ולהגיע להסכמות במשא ומתן.`,
      info11PopUp: "כל טיפוס יכול לנוע לעבר דפוסי התנהגות של שני טיפוסים אחרים",
      info12PopUp: "חץ ההתפתחות",
      info13PopUp:
        "מציג את הפוטנציאל שלנו להתפתח ולהגיע לאיזון ומיצוי היכולות שלנו, כאשר אנחנו במיטבנו ופועלים מתוך מודעות ורצון להשתפר",
      info14PopUp: "חץ הנסיגה",
      info15PopUp:
        "מציג את האפשרות שלנו לסגת לדפוסים המערערים ומרחיקים אותנו ממצוי היכולות שלנו, כאשר אנו לא במיטבנו או במצבי לחץ",
      log1: "משתמשים",
      log2: "לוגים",
      log3: "קופונים",
      log4: "טבלת לוגים",
      log5: "סה״כ רשומים:",
      log6: "חיפוש לוג",
      log7: "סוג",
      log8: "תוכן",
      log9: "id משתמש",
      log10: "מייל",
      log11: "תאריך",
      login1: "משתמש קיים",
      login2: "משתמש חדש",
      login3: "התחברות",
      login4: "מערכת טיפול בהתנגדויות",
      login5: "דואר אלקטרוני",
      login6: "סיסמה",
      login7: "התחברות",
      login8: "שכחתי סיסמה",
      loginMailSentAlert: "נשלח מייל לאיפוס סיסמה",
      loginMailAlert: "היתה בעיה בשליחת המייל",
      myExams1: "הנחה",
      myExams2: "איך לגשת לאבחון?",
      myExams4:
        "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית גולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק.",
      myExams5: "האבחונים שלי",
      myExams6: "אבחון חדש",
      myExams7: "לחצו לרכישה",
      myExams8: "טיפול בהתנגדויות",
      myExams9: "התחלת אבחון חדש",
      myExams10: "כניסה לאבחון",
      myExams11: "האבחון כולל 54 הגידים.",
      myExams12: " בכל היגד יש לסמן ",
      myExams12represents: "מייצג אותי",
      myExams12notRepresents: " / לא מייצג אותי",
      myExams13:
        "כל עוד לא סיימתם את האבחון, ניתן לחזור אחורה ולתקן את הבחירה בכל היגד.",
      myExams14: "יחד עם זאת",
      myExams15: "חשוב שתענו באופן אינטואטיבי",
      myExams16: "כדי לקבל את התוצאות המדויקות עבורכם.",
      myExams17: "כאשר אתם עונים על האבחון, ",
      myExams18: "עליכם להתמקד רק באספקט אחד:",
      myExams19: "ניתן להתמקד בהיבט",
      myExams20: "המקצועי",
      myExams21:
        "(איך שאתם מתנהגים בעבודה, עם קולגות, בעת רכישת מכונית/דירה וכד')",
      myExams22: "או",
      myExams23: "בהיבט",
      myExams24: "האישי",
      myExams25:
        "(איך שאתם מתנהגים עם ההורים, האחים, הילדים, שאר בני המשפחה, החברים וכד')",
      panela1: "משתמשים",
      panela2: "לוגים",
      panela3: "קופונים",
      panela4: "סה״כ",
      panela5: "הוסף קופון",
      panela6: "שם",
      panela7: "חבילה",
      panela8: "הנחה לחבילה",
      panela9: "שימושים",
      panela10: "מומש",
      panela11: "תאריך תפוגה",
      panela12: "פעולות",
      panela13: "מחק",
      panelaSuccessAlert: "בוצע בהצלחה",
      panelaProblemAlert: "היתה בעיה",
      panelModal1: "שם קופון",
      panelModal2: "חבילה",
      panelModal3: "מספר קופונים",
      panelModal4: "תאריך תפוגה",
      panelModal5: "הנחה על החבילה",
      panelModal6: "שמור",
      panelModalPack: "הנחה ",
      purchase1: "ההזמנה שלך",
      purchase2: "הנחה",
      purchase3: "בחירת חבילה",
      purchase4: "קוד קופון",
      purchase5: "הפעלת קופון",
      purchase6: "קוד קופון לא תקין",
      purchase7: "אני מסכים/ה לתנאי השימוש",
      purchase8: "מחיר",
      purchase9: "מעבר לתשלום",
      purchase10: 'סה"כ לתשלום',
      purchase11: "יש לאשר את תנאי השימוש",
      purchaseYouGot: "איזה כיף, יש לכם",
      purchaseDiscount: "אחוז הנחה על",
      purchaseCupon: "הקופון נרשם בהצלחה",
      purchaseSuccess: "הרכישה התבצעה בהצלחה",
      purchaseNewPurchase: "רכישה חדשה\n",
      purchasePrice: "במחיר\n",
      purchasePack1: "אבחון אחד",
      purchasePack2: "אבחון כפול",
      purchasePack3: "אבחון + יעוץ",
      register1: "הרשמה",
      register2: "לא הוזן שם",
      register3: "שם פרטי ושם משפחה",
      register4: 'לא הוזנה כתובת מייל',
      register5: 'דוא"ל',
      register6: "אשר סיסמה",
      register7: "כתובת מייל לא תקינה",
      registerVelidatePassword: "לא הוזנה ססמה",
      registerVelidatePasswordConfirm: "לא הוזן אישור ססמה",
      registerWrongMailAlert: "כתובת מייל לא תקינה",
      registerPassAlert: "הסיסמאות שהוזנו לא זהות",
      registerConfirmAlert: "נשלח מייל לאימות המשתמש ,נרשמתם בהצלחה",
      registerTryAgainAlert: "היתה בעיה בהרשמה אנה נסה שנית",
      title0: "שאלון אבחון - פרטים דמוגרפיים",
      title1: "תוצאות האבחון שלי",
      title2: "האבחונים של",
      title3: "פרטי משתמש",
      results1: "תוצאות האבחון וטקסט על האיניאגרם",
      results2:
        " לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת. הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.ק",
      results3: "למידע נוסף על הטיפוסים",
      results4: "לאבחון אחד על אחד",
      results5: "כל הכבוד סיימת את האבחון!",
      results6:
        "התוצאה שלך ייחודית רק לך ומציגה את תמהיל הטיפוסים האישי שלך, בהיבט אליו בחרת להתייחס (מקצועי/אישי). אין מישהו שהוא בדיוק כמוך!",
      results7:
        "חשוב לזכור שאין טוב ורע, לכן אין צורך להיות שיפוטיים. רק להבין את השילוב המיוחד של הטיפוסים שלך ואיך השילוב הזה יכול לעזור לך במשאים ומתנים הבאים.",
      results8:
        "לכל אחד מאיתנו כמה טיפוסים דומיננטיים (דירוג מעל 4) וכאלה שפחות (דירוג מתחת ל4).",
      results9:
        "מומלץ להתחיל את הקריאה בטיפוסים הדומיננטיים שלך כדי להבין את המניעים העיקריים להתנגדות שלך במשא ומתן ובחיים בכלל.",
      results10:
        "לחיצה על 'למידע נוסף על הטיפוסים' תעביר אותך להרחבה על הטיפוסים השונים.",
      results11:
        "ניתן לנוע בין הטיפוסים השונים באמצעות לחיצה על שם הטיפוס בגלגל או בסרגל מימין.",
      results12: "עברת על המידע של כל הטיפוסים ונשאר לך טעם של עוד?",
      results13: "ניתן",
      results14: "ולהזמין ייעוץ אישי שיתמקד בתוצאות האבחון שלך.",
      results15: "ליצור קשר",
      slideshow: "ניהול משא ומתן בכל תחומי החיים",
      userDetails1: "שלום",
      userDetails2: "פרטי משתמש",
      userDetails3: "שם פרטי",
      userDetails4: "שם משפחה",
      userDetails5: "אימייל",
      userDetails6: "עדכון פרטים",
      userDetails7: "שינוי סיסמה",
      userDetails8: "סיסמה נוכחית",
      userDetails9: "סיסמה חדשה",
      userDetails10: "אימות סיסמה חדשה",
      userDetails11: "עדכון סיסמה",
      userDetailsChangesSuccessAlert: "הפרטים שונו בהצלחה",
      userDetailsProblemAlert: "משהו השתבש נסו שנית מאוחר יותר",
      userDetailsPassAlert: "אימות הסיסמה נכשל, נא להזין סיסמאות זהות",
      userDetailsChangePassAlert: "הסיסמה שונתה בהצלחה",
      userDetailsTryAgainAlert: "משהו השתבש נסו שנית מאוחר יותר",
    },
  };

  // language = this.dataService.language;

  // constructor(public dataService: DataService) {}

  transform(value: string) {
    let lang = localStorage.getItem("lang");
    return this.lang[lang][value];
  }
}
