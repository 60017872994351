import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AlertSystemService } from './alert-system.service';
import { LoginModel } from 'src/app/models/accountModels';
import { DataService } from "./data.service";
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private router: Router,
    private api: ApiService,
    private alertSystemService:AlertSystemService,
    private dataS:DataService
  ) {
      this.ifLoggedIn();
  }

  authState = new BehaviorSubject(false);
  loaderView=true;

  isAuthenticated() {
    return this.authState.value;
  }

  ifLoggedIn() {
  let  response:any = localStorage.getItem('USER_INFO')
   //    console.log('response',response)
       response = JSON.parse(response)
      
      if (response) {
        this.connectTo(response.email,response.password)
        //this.authState.next(true);
      }else{
        this.loaderView=false;
        this.logout();
      }

  }




  logout() {
    localStorage.removeItem('USER_INFO')
      this.router.navigate(['login'],{replaceUrl:true});
      this.authState.next(false);

  } 

    
  async connectTo(emailFromInput2,passwordFromInput2){
    let save: LoginModel = {
      email: emailFromInput2,
      password: passwordFromInput2
    }

    this.api.login(save).subscribe(res => {
      

      if(res == false){
         
         this.alertSystemService.loginFalse();
         this.logout();
     }else{

      this.dataS.user_data = res;

      this.api.saveLog("User login","Login").subscribe(res=>{
        
      })
      if(res["cupon"]){

   
        this.api.checkCuponOnLoad(res["cupon"]).subscribe(res=>{
          if(res && res["price"]){
            this.dataS.disPrice=res["price"];

          }
        })
      }
      localStorage.setItem('USER_INFO', JSON.stringify(save))
       this.router.navigate(['/my-exams']);
       this.authState.next(true);
       setTimeout(() => {
    
         this.loaderView=false;
       }, 600);
       }
       //console.log(res);
     });




  
  };


}
