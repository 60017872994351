import { UserData } from '../pages/users-data/user-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  LoginModel,
  RegisterModel,
  ChangePasswordModel,
  DemographicInfoModel,
  UpdateMyProfileModel,
} from '../models/accountModels';
import {
  GetUserExamsModel,
  GetUserExamsResponseData,
} from '../models/examsModels';
import { ApiResponse, ApiResponseGeneric } from '../models/baseModels';

import { map } from 'rxjs/operators';
import {
  ExamModel,
  PackageModel,
  CharacterTypeInfoModel,
} from '../models/ListModels';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // private apiUrl = 'http://api.sheelon.ori-pc.com/api';
  private urlNew = 'https://app.pan-systems.com/server/api3.php';
  private urlNew1 = 'https://app.pan-systems.com/server/api.php';

  constructor(private http: HttpClient, private dataS: DataService) {}

  user_data: any;
  exams_user: any = [];

  getUserExamsFromClient() {
    this.getUserExams({ userId: this.dataS.user_data.id }).subscribe(
      (data: any) => {
        // this.exams = data.payload.exams;
        var res: any = data;

        data.forEach((element) => {
          // console.log('element',element);
          element.date = new Date(element.date);
        });

        // console.log(data);

        this.exams_user = data;
      },
    );
  }

  getAllLogs() {
    let data = {
      type: 'getalllogs',
    };
    return this.http.post(this.urlNew, data);
  }
  checkCupon(cupon) {
    let data = {
      cupon: cupon,
      userid: this.dataS.user_data.id,
      type: 'checkCupon',
    };
    return this.http.post(this.urlNew, data);
  }

  saveExamName(value) {
    let data = {
      examName: value,
      exam_id: this.dataS.exam_id,
      type: 'saveNameToExam',
    };
    return this.http.post(this.urlNew, data);
  }
  checkCuponOnLoad(cupon) {
    let data = {
      cupon: cupon,
      userid: this.dataS.user_data.id,
      type: 'checkCuponOnLoad',
    };
    return this.http.post(this.urlNew, data);
  }

  //general service:::::

  //cache
  public characterTypeInfoItems: CharacterTypeInfoModel[];
  public packages: PackageModel[];
  private exams: ExamModel[];
  private userId: number;
  private email: string;

  public loadCache() {
    // this.getAllCharacterTypes().subscribe(data => {

    //   this.characterTypeInfoItems = data.payload;
    //   console.log(data);
    // });

    this.getAllPackages().subscribe((data) => {
      // this.packages = data.payload;
    });

    this.getAllExams().subscribe((data) => {
      // this.exams = data.payload;
    });
  }

  // register(params): Observable<any> {
  //   var request = {

  //     firstName: params.name.split(' ').slice(0, -1).join(' '),
  //     lastName: params.name.split(' ').slice(-1).join(' '),
  //     email: params.email,
  //     password: params.password,
  //     verifyPassword: params.password,
  //   }
  //   return this.http.post('http://api.sheelon.ori-pc.com/api/account/register', request);
  // }

  // account::::
  register(data: RegisterModel): Observable<any> {
    let nData: any = data;
    nData.type = 'registerUser';

    return this.http.post(this.urlNew, nData);
  }

  login(data: LoginModel): Observable<any> {
    let nData: any = data;
    nData.type = 'login';
    return this.http.post(this.urlNew, nData);
  }

  getAllCupons() {
    let nData: any = {};
    nData.type = 'getAllCupons';
    return this.http.post(this.urlNew, nData);
  }

  updateMyProfile(data) {
    let nData: any = data;
    nData.type = 'updateUser';
    return this.http.post(this.urlNew, nData);
    // return this.http.post(this.apiUrl + '/account/updateMyProfile', data);
  }

  deletUser(id) {
    let nData: any = {};
    nData.id = id;
    nData.type = 'deletUser';
    return this.http.post(this.urlNew, nData);
  }
  deletPac(id) {
    let nData: any = {};
    nData.id = id;
    nData.type = 'deletPac';
    return this.http.post(this.urlNew, nData);
  }

  getAllUsers() {
    let nData: any = {};
    nData.type = 'getAllUsers';
    return this.http.post(this.urlNew, nData);
    // return this.http.post(this.apiUrl + '/account/updateMyProfile', data);
  }

  updateUser(data) {
    let nData: any = data;
    nData.type = 'updateUser';
    return this.http.post(this.urlNew1, nData);
  }

  resetPassword(email) {
    let nData = {
      email: email,
      type: 'resetPassword',
    };

    return this.http.post(this.urlNew, nData);
  }

  sendEmailToOrna(subTitle, superContant) {
    let nData = {
      email: this.dataS.user_data.email,
      subTitle: subTitle,
      superContant: superContant,

      type: 'sendmail',
    };

    return this.http.post(this.urlNew, nData);
  }

  changePassword(data) {
    let nData: any = data;
    nData.type = 'changePassword';
    return this.http.post(this.urlNew1, nData);
  }

  panelPass(pass) {
    let nData: any = {};
    nData.type = 'panelPass';
    nData.pass = pass;
    return this.http.post(this.urlNew1, nData);
  }

  // updateMyProfile(data: UpdateMyProfileModel) {
  //   return this.http.post(this.apiUrl + '/account/updateMyProfile', data);
  // }

  // changePassword(data: ChangePasswordModel) {
  //   return this.http.post(this.apiUrl + '/account/changePassword', data);
  // }

  // verifyEmail(data: VerifyEmailModel) {

  // }

  saveDemographicInfo(data: DemographicInfoModel) {
    let nData: any = data;
    nData.type = 'DemographicInfoSave';
    return this.http.post(this.urlNew, nData);
    // return this.http.post(this.apiUrl + '/account/saveDemographicInfo', data);
  }
  saveCupons(data) {
    let nData: any = data;
    nData.type = 'saveCupons';

    return this.http.post(this.urlNew, nData);
    // return this.http.post(this.apiUrl + '/account/saveDemographicInfo', data);
  }

  getExamsFromCache(): ExamModel[] {
    return [...this.exams];
  }

  // Packages::::

  removeUserCupon() {
    {
      let data = {
        userid: this.dataS.user_data.id,
        type: 'removeUserCupon',
      };
      return this.http.post(this.urlNew, data);
      //    return this.http.post(this.apiUrl + '/account/register', data);
    }
  }
  addUserPackage(count) {
    let data = {
      count: count,
      userid: this.dataS.user_data.id,
      type: 'addUserPackage',
      exam_id: '1',
    };
    return this.http.post(this.urlNew, data);
    //    return this.http.post(this.apiUrl + '/account/register', data);
  }

  saveLog(typelog, content) {
    let data = {
      typelog: typelog,
      userid: this.dataS.user_data.id,
      type: 'saveLog',
      content: content,
      email: this.dataS.user_data.email,
    };

    return this.http.post(this.urlNew, data);
    //    return this.http.post(this.apiUrl + '/account/register', data);
  }

  getCoupon(data) {
    // return this.http.post(this.apiUrl + '/account/register', data);
  }

  redeemCoupon(data) {
    // return this.http.post(this.apiUrl + '/account/register', data);
  }

  commitPurchasePackage(data) {
    // return this.http.post(this.apiUrl + '/account/register', data);
  }

  // Exams:::::

  getUserExams(data: GetUserExamsModel) {
    let nData: any = data;
    nData.type = 'getuserExam';

    return this.http.post(this.urlNew, nData);

    // return this.http.get<ApiResponseGeneric<GetUserExamsResponseData>>(`${this.apiUrl}/exams?userId=1`);
  }

  getExamResult(data: GetUserExamsModel) {
    // return this.http.post(this.apiUrl + '/account/register', data);
  }

  saveExamResult(exam, user_id, exam_id) {
    var arrayScorre = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    exam.forEach((element) => {
      if (element.answer == true) {
        arrayScorre[element.index - 1]++;
      } else {
      }
    });

    this.dataS.currentExam['examResults'] = JSON.stringify(arrayScorre);

    let nData: any = new Object();
    nData.score = JSON.stringify(arrayScorre);
    nData.user_id = user_id;
    // nData.score1=1;
    // nData.score2=2;
    // nData.score3=3;
    // nData.score4=5;
    // nData.score5=1;
    // nData.score6=1;
    // nData.score7=6
    // nData.score9=1;

    nData.examId = exam_id;
    nData.type = 'saveExamResult';

    return this.http.post(this.urlNew, nData);

    // return this.http.post(this.apiUrl + '/account/register', data);
  }

  // Lists (=cache)::::

  // getAllCharacterTypes(): Observable<ApiResponseGeneric<CharacterTypeInfoModel[]>> {
  //   // return this.http.get<ApiResponseGeneric<CharacterTypeInfoModel[]>>(this.apiUrl + '/lists/getAllCharacterTypes');
  // }

  getAllPackages() {
    let nData: any = new Object();
    nData.type = 'getAllPackages';
    return this.http.post(this.urlNew, nData);

    // return this.http.get<ApiResponseGeneric<PackageModel[]>>(this.apiUrl + '/lists/getAllPackages');
  }
  getAllPackages2() {
    let nData: any = new Object();
    nData.type = 'getAllPackages2';
    return this.http.post(this.urlNew, nData);

    // return this.http.get<ApiResponseGeneric<PackageModel[]>>(this.apiUrl + '/lists/getAllPackages');
  }

  getAllExams() {
    let nData: any = new Object();
    nData.exam_id = 1;
    nData.type = 'getAllExams';
    return this.http.post(this.urlNew, nData);

    // return this.http.get<ApiResponseGeneric<ExamModel[]>>(this.apiUrl + '/lists/getAllExams');
  }
}
